import { Avatar, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function CoverIcon({
  imageUrl,
  title,
  id,
  type,
  onClick,
}: {
  imageUrl: string;
  title: string;
  id: string;
  type: string;
  onClick?: () => void;
}) {
  return (
    <div onClick={onClick}>
      <Link to={`/${type}/${id}`}>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",

          gap: "10px",
        }}>
          <Avatar
            src={imageUrl}
            sx={{
              width: "200px",
              height: "200px",
            }}
          />
          <Typography
            sx={{
              fontSize: "1.25rem",
              fontWeight: "bold",
              textAlign: "left",
              width: "200px",

              // all text one line
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {title}
          </Typography>
        </Box>
      </Link>
    </div>
  );
}

export default CoverIcon;
