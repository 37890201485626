import { Box } from "@mui/material";

function VisulizerAnim({
  playing,
} : {
  playing: boolean;
}) {
  return (
    <Box sx={{
        width: '20px',
        height: '20px',
    }}>
      <div className={ playing ? "visualizer__bars" : "visualizer__bars_paused" }>
        <div className="visualizer__bar" />
        <div className="visualizer__bar" />
        <div className="visualizer__bar" />
      </div>
    </Box>
  );
}

export default VisulizerAnim;
