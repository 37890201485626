import {
  TableRow,
  TableCell,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MoreVert, PlayArrow, YouTube } from "@mui/icons-material";
import SessionManager from "../sessionManager";
import VisulizerAnim from "./VisulizerAnim";

// states
import usePlayback from "../states/PlaybackState";

function FavoriteTrackRow({
  index,
  trackName,
  trackId,
  link,
  onPlayNow,
  onPlayNext,
  onAddToQueue,
  currSession,
  albumName,
  albumId,
  artistName,
  artistId,
  thumbnail,
}: {
  index: number;
  trackName: string;
  trackId: string;
  link?: string;
  onPlayNow?: () => void;
  onPlayNext?: () => void;
  onAddToQueue?: () => void;
  currSession: SessionManager | undefined;
  albumName: string;
  albumId: string;
  artistName: string;
  artistId: string;
  thumbnail: string;
}) {
  const [hovered, setHovered] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { playing } = usePlayback();

  // for force re-render
  const [currTime, setCurrTime] = useState<number>(Date.now());
  useEffect(() => {
    if (!currSession) return;
    const listener = () => {
      setCurrTime(Date.now());
    };
    currSession.on("playback_next", listener);
    currSession.on("playback_start", listener);
    currSession.on("queue_update", listener);
    return () => {
      currSession.off("playback_next", listener);
      currSession.off("playback_start", listener);
      currSession.off("queue_update", listener);
    };
  }, [currSession]);

  return (
    <>
      <TableRow
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        sx={{
          backgroundColor: hovered ? "#00000055" : "transparent",
          transition: "background-color 0.2s ease-in-out",
        }}
      >
        <TableCell
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            width: "35px",
            cursor: "pointer",
          }}
          align="left"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            {(() => {
              if (currSession?.queue.current()?.trackID === trackId)
                return <VisulizerAnim playing={playing} />;
              if (hovered)
                return (
                  <IconButton
                    onClick={onPlayNow}
                    sx={{
                      color: "#FFFFFF",
                      padding: "1px",
                    }}
                  >
                    <PlayArrow
                      sx={{
                        color: "#FFFFFF",
                        fontSize: "20px",
                      }}
                    />
                  </IconButton>
                );
              return (
                <Typography
                  sx={{
                    color: "#FFFFFF",
                    fontSize: "14px",
                    fontFamily: "monospace",
                    fontWeight: "bold",
                  }}
                >
                  {index}
                </Typography>
              );
            })()}
          </Box>
        </TableCell>
        <TableCell
          sx={{
            width: "50px",
            height: "50px",
          }}
          align="left"
        >
          <Box
            sx={{
              width: "50px",
              height: "50px",
              backgroundImage: `url(${thumbnail})`,
              backgroundSize: "cover",
              backgroundBlendMode: "darken",
            }}
          />
        </TableCell>
        <TableCell>{trackName}</TableCell>
        <TableCell align="right">
          <Link
            to={
              albumId === "youtube"
                ? `/youtube/${trackId}`
                : `/artist/${artistId}`
            }
          >
            {" "}
            {artistName}{" "}
          </Link>
        </TableCell>
        <TableCell align="right">
          {albumId === "youtube" ? (
            <Link to={`/youtube/${trackId}`}>
              <YouTube
                sx={{
                  color: "#FFFFFF",
                  fontSize: "20px",
                }}
              />
            </Link>
          ) : (
            <Link to={`/album/${albumId}`}> {albumName} </Link>
          )}
        </TableCell>
        <TableCell
          align="right"
          sx={{
            width: "64px",
          }}
        >
          <IconButton
            sx={{
              color: "#FFFFFF",
              padding: "0px",
            }}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            <MoreVert
              sx={{
                color: "#FFFFFF",
                fontSize: "20px",
              }}
            />
          </IconButton>
        </TableCell>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={() => {
            setAnchorEl(null);
            setHovered(false);
          }}
          onClick={() => {
            setAnchorEl(null);
            setHovered(false);
          }}
        >
          <MenuItem onClick={() => onPlayNext?.()}>Play Next</MenuItem>
          <MenuItem onClick={() => onAddToQueue?.()}>Add to Queue</MenuItem>
          {link && (
            <>
              <Divider />
              <MenuItem
                onClick={() => {
                  window.open(link, "_blank");
                }}
              >
                {albumId === "youtube" ? "Open on Youtube" : "Open on Spotify"}
              </MenuItem>
            </>
          )}
        </Menu>
      </TableRow>
    </>
  );
}

export default FavoriteTrackRow;
