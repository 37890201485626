import { Box, IconButton, ListItem, Tooltip, Typography } from "@mui/material";
import { Types } from "../types";
import { MoreVert, MusicNote } from "@mui/icons-material";

import { MouseEvent } from "react";

function SessionUser({
  user,
  isHost,
  canModerate,
  onUserProfile,
}: {
  user: Types.Member;
  isHost: boolean;
  canModerate: boolean;
  onUserProfile: (
    event: MouseEvent<HTMLDivElement, MouseEvent>,
    user: Types.Member
  ) => void;
}) {
  return (
    <Box
      sx={{
        backgroundImage: `url(${user.avatarURL})`,
        backgroundSize: "cover",
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        backgroundBlendMode: "multiply",

        cursor: "pointer",

        "&:hover": {
          backgroundColor: "#ffffff11",
        },
      }}
      onClick={(event) => onUserProfile(event as any, user)}
    />
  );
}

export default SessionUser;
