import { Queue, Types } from './types';
import { Socket } from 'socket.io-client';
import EventEmitter from 'events';
import { Spotify } from './spotify';
import { favManager } from './App';
import { Favourite } from './favouritesManager';
import { useToast } from './components/ToastManager';

export default class SessionManager extends EventEmitter {
    SessionID: string;
    users: Types.Member[] = [];
    queue: Queue = new Queue();
    settings: Types.SessionSettings;
    socket: Socket;

    playback: {
        paused: boolean,
        autoplay: boolean,
    };


    constructor({
        sessionID, socket, playback, queue, users, settings
    }: {
        sessionID: string, socket: Socket, playback: Types.PlaybackState, queue?: Types.QueueItem[], users?: Types.Member[], settings: Types.SessionSettings
    }) {
        super();
        this.setMaxListeners(100);
        this.SessionID = sessionID;
        this.users = users || [];
        this.settings = settings;
        this.socket = socket;
        this.playback = {
            paused: playback.paused,
            autoplay: playback.autoplay
        };
        if(queue) this.queue.setQueue(queue);

        this.socket.on("playback_resume", () => {
            if(!this.playback.paused) return;
            console.log("playback_resume");
            this.playback.paused = false;
            this.emit("playback_resume");
        })
        this.socket.on("playback_pause", () => {
            if(this.playback.paused) return;
            console.log("playback_pause");
            this.playback.paused = true;
            this.emit("playback_pause");
        })
        this.socket.on("playback_end", () => {
            console.log("playback_end");
            this.queue.clear();
            this.emit("playback_end");
        })
        this.socket.on("playback_seek", (seek: number) => {
            console.log("playback_seek");
            this.emit("playback_seek", seek);
        })
        this.socket.on("playback_next", () => {
            console.log("playback_next");
            this.queue.next();
            this.emit("playback_next");
        })
        this.socket.on("playback_previous", () => {
            console.log("playback_previous");
            this.emit("playback_seek", 0);
        })
        this.socket.on("playback_resync", (data: {
            playback: Types.PlaybackState,
            queue: Types.QueueItem[],
        }) => {
            console.log("playback_resync");
            console.log(data);
            this.playback.autoplay = data.playback.autoplay;
            this.playback.paused = data.playback.paused;

            if(data.queue !== this.queue.getQueue()) {
                this.queue.setQueue(data.queue);
                this.emit("queue_update", queue);
            }

            this.emit("playback_resync", data.playback);
        })

        this.socket.on("session_userJoined", (data: Types.Member) => {
            console.log("session_userJoined");
            this.users.push(data);
            this.emit("session_resync_users");
        })

        this.socket.on("session_userLeft", (id: string) => {
            console.log("session_userLeft", id);
            this.users = this.users.filter(user => user.socket !== id);
            this.emit("session_resync_users");
        })

        this.socket.on("session_resync_users", (users: Types.Member[]) => {
            console.log("session_resync_users");
            this.users = users;
            this.emit("session_resync_users", users);
        })

        this.socket.on("session_kicked", () => {
            console.log("session_kicked");
            this.emit("session_kicked");
        })

        this.socket.on("session_banned", () => {
            console.log("session_banned");
            this.emit("session_banned");
        })

        this.socket.on("session_resync_settings", (settings: Types.SessionSettings) => {
            console.log("session_resync_settings");
            this.settings = settings;
            this.emit("session_resync_settings", settings);
        })

        this.socket.on("queue_update", (queue: Types.QueueItem[]) => {
            console.log("queue_update");
            this.queue.setQueue(queue);
            this.emit("queue_update", queue);
        });

        const toastrOptions: ToastrOptions = {
            progressBar: true,
            timeOut: 2000,
        }

        this.socket.on("queue_update_now", (data: Types.QueueUpdateRequest) => {
            console.log("queue_update_now");
            this.emit("queue_update_now", data);

            if(data.multiple && typeof data.tracks === "number") useToast.getState().addToast(data.user, "PlayNow", `Now Playing ${data.tracks} tracks`, 4000);
            else if(typeof data.tracks !== "number") useToast.getState().addToast(data.user, "PlayNow", `${data.tracks.artist} - ${data.tracks.title}`, 4000);
        })
        this.socket.on("queue_update_add", (data: Types.QueueUpdateRequest) => {
            console.log("queue_update_add");
            this.emit("queue_update_add", data);

            if(data.multiple && typeof data.tracks === "number") useToast.getState().addToast(data.user, "QueueAdd", `Added ${data.tracks} tracks to queue`, 4000);
            else if(typeof data.tracks!== "number") useToast.getState().addToast(data.user, "QueueAdd", `${data.tracks.artist} - ${data.tracks.title}`, 4000);
        })
        this.socket.on("queue_update_next", (data: Types.QueueUpdateRequest) => {
            console.log("queue_update_next");
            this.emit("queue_update_next", data);

            if(data.multiple && typeof data.tracks === "number") useToast.getState().addToast(data.user, "QueueNext", `Added ${data.tracks} tracks play next`, 4000);
            else if(typeof data.tracks!== "number") useToast.getState().addToast(data.user, "QueueNext", `${data.tracks.artist} - ${data.tracks.title}`, 4000);
        })

        setTimeout(() => {
            console.log("playback_start");
            this.emit("playback_start", {
                paused: playback.paused,
                time: playback.time
            });
        }, 500);

        favManager.on("add", (data: Favourite) => {
            if(data.type === "track" && this.queue.current()?.trackID === data.id) this.emitAddedToFav(data.id);
        })

        this.socket.on("usr_fav_praise", (data: {
            initiator: Types.Member,
            track: string
        }) => {
            this.emit("usr_fav_praise", data);
        })
    }

    next() {
        this.socket.emit("playback_next");
    }

    prev() {
        this.socket.emit("playback_previous");
    }

    seek(seek: number) {
        this.socket.emit("playback_seek", seek);
    }

    pause() {
        this.socket.emit("playback_pause");
    }

    resume() {
        this.socket.emit("playback_resume");
    }

    stop() {
        this.socket.emit("playback_end");
    }

    removeQueueItem(index: number) {
        this.socket.emit("queue_remove", index);
    }

    setPlaybackOptions(options: Partial<{
        autoplay: boolean
    }>) {
        this.socket.emit("playback_setOptions", options);
        if(options.autoplay) this.playback.autoplay = options.autoplay;
    }

    async playNow(id: string | string[]): Promise<Types.CallbackSuccess | Types.CallbackError> {
        return new Promise((resolve, reject) => {
            this.socket.emit("queue_now", id, ((res: Types.CallbackSuccess | Types.CallbackError) => {
                if (res.error) return reject(res);
                else resolve(res);
            }));
        }); 
    }

    async addToQueue(id: string | string[]): Promise<Types.CallbackSuccess | Types.CallbackError> {
        return new Promise((resolve, reject) => {
            this.socket.emit("queue_add", id, ((res: Types.CallbackSuccess | Types.CallbackError) => {
                if (res.error) return reject(res);
                else resolve(res);
            }));
        }); 
    }

    async playNext(id: string | string[]): Promise<Types.CallbackSuccess | Types.CallbackError> {
        return new Promise((resolve, reject) => {
            this.socket.emit("queue_next", id, ((res: Types.CallbackSuccess | Types.CallbackError) => {
                if (res.error) return reject(res);
                else resolve(res);
            }));
        }); 
    }

    async playAlbumNow(id: string): Promise<Types.CallbackSuccess | Types.CallbackError> {
        const tracks = await Spotify.getAlbumTracks(id);
        if(!tracks) return {
            error: true,
            type: "album_not_found",
            message: "Album not found"
        } satisfies Types.CallbackError;
        tracks.items = tracks.items.slice(0, 25);
        return new Promise((resolve, reject) => {
            this.socket.emit("queue_now", tracks.items.map(track => track.id), ((res: Types.CallbackSuccess | Types.CallbackError) => {
                if (res.error) return reject(res);
                else resolve(res);
            }));
        }); 
    }

    async playAlbumNext(id: string): Promise<Types.CallbackSuccess | Types.CallbackError> {
        const tracks = await Spotify.getAlbumTracks(id);
        if(!tracks) return {
            error: true,
            type: "album_not_found",
            message: "Album not found"
        } satisfies Types.CallbackError;
        tracks.items = tracks.items.slice(0, 25);
        return new Promise((resolve, reject) => {
            this.socket.emit("queue_next", tracks.items.map(track => track.id), ((res: Types.CallbackSuccess | Types.CallbackError) => {
                if (res.error) return reject(res);
                else resolve(res);
            }));
        }); 
    }

    async addAlbumToQueue(id: string): Promise<Types.CallbackSuccess | Types.CallbackError> {
        const tracks = await Spotify.getAlbumTracks(id);
        if(!tracks) return {
            error: true,
            type: "album_not_found",
            message: "Album not found"
        } satisfies Types.CallbackError;
        tracks.items = tracks.items.slice(0, 25);
        return new Promise((resolve, reject) => {
            this.socket.emit("queue_add", tracks.items.map(track => track.id), ((res: Types.CallbackSuccess | Types.CallbackError) => {
                if (res.error) return reject(res);
                else resolve(res);
            }));
        }); 
    }

    async getYoutubeInfo(id: string): Promise<Types.YoutubeInfo | null> {
        return new Promise((resolve, reject) => {
            this.socket.emit("youtube_info", id, ((res: Types.YoutubeInfo | null) => {
                if (res === null) return reject(res);
                else resolve(res);
            }));
        }); 
    }

    // make sure to only use this with tracks
    async emitAddedToFav(id: string): Promise<void> {
        this.socket.emit("usr_fav_add", id);
    }
}