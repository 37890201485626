import { Close, QueueMusic, Remove } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Drawer,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Queue } from "../types";
import SessionManager from "../sessionManager";

function QueueButton({
  queue,
  currSession,
}: {
  queue: Queue | undefined;
  currSession: SessionManager | undefined;
}) {
  const [open, setOpen] = useState<boolean>(false);

  if (!queue) return <></>;

  //   use and mui Drawler on the right side of the screen to display the queue

  return (
    <Box>
      <Tooltip title="Manage Queue">
        <IconButton
          sx={{ fontSize: 20 }}
          style={{
            color: "#fff",
            marginRight: "10px",
            fontSize: "20px",
          }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <QueueMusic fontSize="small" />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        style={{
          zIndex: 4000,
        }}
      >
        <Box
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            width: "100%",
          }}
        >
          <Typography
            variant="h5"
            textAlign="center"
            style={{ padding: "10px" }}
          >
            Queue
          </Typography>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              gap: "5px",
              width: "100%",
            }}
          >
            {queue.getItems().length === 0 && (
              <Typography>No songs in queue</Typography>
            )}
            {queue.getItems().map((song, index) => (
              <Box
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                  flexDirection: "row",
                  padding: "5px",
                  gap: "5px",
                  backgroundColor: "#00000055",
                  width: "100%",
                }}
              >
                <Box
                  style={{
                    borderRadius: "5px",
                    backgroundImage: `linear-gradient(#00000033, #00000033), url(${song.thumbnail})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "50px",
                    height: "50px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    style={{
                      position: "absolute",
                      textAlign: "center",
                      fontWeight: "bold",
                      color: "#FFFFFF",
                    }}
                  >
                    {index + 1}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginRight: "20px",
                  }}
                >
                  <Typography fontWeight="bold" letterSpacing={1.1}>
                    {song.title}
                  </Typography>
                  <Typography>
                    {song.artist} - {song.album}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginLeft: "auto",
                    alignSelf: "center",

                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title={song.member.username} arrow placement="left" sx={{
                    zIndex: 5000,
                  }}>
                    <Avatar
                      sx={{
                        width: "30px",
                        height: "30px",
                      }}
                      src={song.member.avatarURL}
                    />
                  </Tooltip>
                  <IconButton
                    onClick={() => {
                      currSession?.removeQueueItem(index + 1);
                    }}
                  >
                    <Close />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}

export default QueueButton;
