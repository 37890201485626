import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import SessionManager from "../sessionManager";
import { Spotify } from "../spotify";

// components
import SearchBar from "../components/Searchbar";
import AlbumCard from "../components/AlbumCard";

function Home({ currSession }: { currSession: SessionManager | undefined }) {
  const [newReleases, setNewReleases] =
    useState<SpotifyApi.ListOfNewReleasesResponse | null>();

  useEffect(() => {
    Spotify.getNewReleases().then((res) => {
      setNewReleases(res);
    });
  }, []);

  return (
    <Box
      style={{
        width: "95%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        marginLeft: "40px",
      }}
    >
      <h1
        style={{
          textAlign: "center",
          marginTop: "40px",
          marginBottom: "40px",
          fontSize: "40px",
        }}
      >
        👋 Hey there{" "}
        <strong
          style={{
            textTransform: "capitalize",
            fontWeight: "bold",
          }}
        >
          {localStorage.getItem("username")}
        </strong>
      </h1>

      <SearchBar
        style={{
          width: "95%",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          width: "100%",
          height: "auto",
          marginTop: "30px",
          marginBottom: "30px",
        }}
      >
        <Typography
          sx={{
            fontSize: "30px",
            fontWeight: "bold",
          }}
        >
          New Releases
        </Typography>
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          {newReleases?.albums.items.length === 0 && (
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              No new releases
            </Typography>
          )}
          {newReleases === null && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {newReleases?.albums.items.map((album) => (
            <AlbumCard
              key={album.id}
              albumName={album.name}
              albumExtra={`${album.artists[0].name} - ${album.album_type}`}
              artistName={album.artists[0].name}
              albumImage={album.images[0].url}
              albumLink={`/album/${album.id}`}
              albumID={album.id}
              artistID={album.artists[0].id}
              albumSpotifyLink={album.external_urls.spotify}
              currSession={currSession}
              onPlayNow={() => currSession?.playAlbumNow(album.id)}
              onPlayNext={() => currSession?.playAlbumNext(album.id)}
              onAddToQueue={() => currSession?.addAlbumToQueue(album.id)}
            />
          ))}
        </Grid>
      </Box>

      <Typography
        sx={{
          fontSize: "15px",
          marginTop: "30px",
          marginBottom: "100px",
          alignSelf: "center",
          color: "#ffffffAA",
        }}
      >
        All content is provided by{" "}
        <a
          style={{ fontWeight: "bold" }}
          href="http://spotify.com"
          target="_blank"
          rel="noreferrer"
        >
          Spotify
        </a>
      </Typography>
    </Box>
  );
}

export default Home;
