import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";

function NavBarButton({
  to,
  icon,
  primary,
}: {
  to: string;
  icon: JSX.Element;
  primary: string;
}) {
  const location = useLocation();

  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      <ListItem disablePadding sx={{ display: "block" }}>
        <Box sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
          height: "50px",
          px: "5px",
          boxShadow: "0px 2px 5px 2px #00000055",
          borderRadius: "50px",

          pl: 3,
          
          "&:hover": {
            backgroundColor: "#ffffff11",
          },
        }}>
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: 3,
              justifyContent: "center",
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            sx={{
              color: "#fff",
              fontSize: "1rem",
            }}
          >
            {primary}
          </ListItemText>
        </Box>
      </ListItem>
    </Link>
  );
}

export default NavBarButton;
