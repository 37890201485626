import {
  DoNotDisturbAlt,
  DoNotStep,
  Home as HomeIcon,
  HomeRounded,
  MusicNote,
  People,
  PeopleRounded,
  Search as SearchIcon,
  SearchRounded,
  Settings,
  Settings as SettingsIcon,
  Share as ShareIcon,
  Star,
  StarRounded,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import SessionManager from "../sessionManager";
import { useEffect, useState } from "react";
import { Types } from "../types";

// components
import NavBarButton from "./NavBarButton";
import SessionUser from "./SessionUser";
import SharePopup from "./SharePopup";

// states
import useUI from "../states/UIStates";
import SessionSettingsPopup from "./SessionSettingsPopup";

function Sidebar() {
  const { windowFullScreen } = useUI();

  return (
    <Drawer variant="permanent" open={true} sx={{
      border: "none",

      "& .MuiDrawer-paper": {
        border: "none",
      },
    }}>
      <Box
        sx={{
          width: "200px",
          marginTop: "64px",
          transition: windowFullScreen
            ? "all 1s ease-in-out"
            : "all 0.2s linear",
          marginLeft: windowFullScreen ? "-210px" : "0px",
        }}
      >
        <List
          sx={{
            pt: "10px",
            pb: "0px",
          }}
        >
          <NavBarButton
            to="/"
            icon={<HomeRounded />}
            primary="Home"
          />
          <NavBarButton
            to="/search"
            icon={<SearchRounded />}
            primary="Search"
          />
          <NavBarButton
            to="/favorites"
            icon={<StarRounded />}
            primary="Favorites"
          />
          <NavBarButton
            to="/session"
            icon={<PeopleRounded />}
            primary="Session"
          />
          {/* <NavBarButton
            to="/settings"
            icon={<SettingsIcon />}
            primary="Settings"
          /> */}
        </List>
        {/* <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            px: "10px",
            py: "10px",
            width: "100%",
          }}
        >
          <Typography
            textAlign="center"
            sx={{
              display: "block"
            }}
          >
            Session
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton
              onClick={() => setShareOpen(true)}
              size="small"
              sx={{
                display: "initial",
                width: "30px",
                height: "30px",
              }}
            >
              <Tooltip title="Invite" placement="right">
                <ShareIcon fontSize="small" />
              </Tooltip>
            </IconButton>

            <IconButton
              onClick={() => setSettingsOpen(true)}
              size="small"
              sx={{
                display:
                  users &&
                  users[0]?.socket === session?.socket.id
                    ? "initial"
                    : "none",
                width: "30px",
                height: "30px",
              }}
            >
              <Tooltip title="Settings" placement="right">
                <Settings fontSize="small" />
              </Tooltip>
            </IconButton>
          </Box>
        </Box>
        <List
          sx={{
            pt: "0px",
          }}
        >
          {users.map((user, index) => (
            <SessionUser
              key={user.socket}
              user={user}
              isHost={index === 0}
              canModerate={users[0].socket === session?.socket.id}
              onModerate={(event, user) => {
                setAnchorTargetUser(user);
                setAnchorEl(event.currentTarget);
              }}
            />
          ))}
        </List> */}
      </Box>
    </Drawer>
  );
}

export default Sidebar;
