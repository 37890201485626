import axios from "axios";
import { Types } from "./types";

export namespace Spotify {
    export type SearchTypes = 'track' | 'artist' | 'album' | 'playlist';

    export interface SearchQuery {
        query: string;
        type: Spotify.SearchTypes | Spotify.SearchTypes[];
        offset?: number;
        limit?: number;
    }

    export async function search({ query, type, offset, limit }: Spotify.SearchQuery): Promise<SpotifyApi.SearchResponse | null> {
        let id = localStorage.getItem('id');
        let token = localStorage.getItem('token');
        if(!id || !token) return null;

        const data = await axios.post(`${process.env.REACT_APP_STREAM_SERVER_URL}/spotify/search`, {
            query,
            type,
            offset,
            limit,
            id,
            token,  
        } satisfies Types.SearchQuery).then(res => res.data).catch(() => null);
        if(!data) return null;
        return data.body;
    }

    export async function getArtist(artistID: string): Promise<SpotifyApi.SingleArtistResponse | null> {
        let id = localStorage.getItem('id');
        let token = localStorage.getItem('token');
        if(!id || !token) return null;

        const data = await axios.post(`${process.env.REACT_APP_STREAM_SERVER_URL}/spotify/artist/${artistID}`, {
            id,
            token,  
        } satisfies Types.AuthedQuery).then(res => res.data).catch(() => null);
        if(!data) return null;
        return data.body;
    }

    export async function getArtistTopTracks(artistID: string): Promise<SpotifyApi.ArtistsTopTracksResponse | null> {
        let id = localStorage.getItem('id');
        let token = localStorage.getItem('token');
        if(!id || !token) return null;

        const data = await axios.post(`${process.env.REACT_APP_STREAM_SERVER_URL}/spotify/artist/${artistID}/topTracks`, {
            id,
            token,  
        } satisfies Types.AuthedQuery).then(res => res.data).catch(() => null);
        if(!data) return null;
        return data.body;
    }

    export async function getArtistAlbums(artistID: string): Promise<SpotifyApi.ArtistsAlbumsResponse | null> {
        let id = localStorage.getItem('id');
        let token = localStorage.getItem('token');
        if(!id || !token) return null;

        const data = await axios.post(`${process.env.REACT_APP_STREAM_SERVER_URL}/spotify/artist/${artistID}/albums`, {
            id,
            token,  
        } satisfies Types.AuthedQuery).then(res => res.data).catch(() => null);
        if(!data) return null;
        return data.body;
    }

    export async function getArtistRelatedArtists(artistID: string): Promise<SpotifyApi.ArtistsRelatedArtistsResponse | null> {
        let id = localStorage.getItem('id');
        let token = localStorage.getItem('token');
        if(!id || !token) return null;

        const data = await axios.post(`${process.env.REACT_APP_STREAM_SERVER_URL}/spotify/artist/${artistID}/relatedArtists`, {
            id,
            token,
        } satisfies Types.AuthedQuery).then(res => res.data).catch(() => null);
        if(!data) return null;
        return data.body;
    }

    export async function getAlbum(albumID: string): Promise<SpotifyApi.SingleAlbumResponse | null> {
        let id = localStorage.getItem('id');
        let token = localStorage.getItem('token');
        if(!id || !token) return null;

        const data = await axios.post(`${process.env.REACT_APP_STREAM_SERVER_URL}/spotify/album/${albumID}`, {
            id,
            token,  
        } satisfies Types.AuthedQuery).then(res => res.data).catch(() => null);
        if(!data) return null;
        return data.body;
    }

    export async function getAlbumTracks(albumID: string): Promise<SpotifyApi.AlbumTracksResponse | null> {
        let id = localStorage.getItem('id');
        let token = localStorage.getItem('token');
        if(!id || !token) return null;

        const data = await axios.post(`${process.env.REACT_APP_STREAM_SERVER_URL}/spotify/album/${albumID}/tracks`, {
            id,
            token,  
        } satisfies Types.AuthedQuery).then(res => res.data).catch(() => null);
        if(!data) return null;
        return data.body;
    }

    export async function getNewReleases(): Promise<SpotifyApi.ListOfNewReleasesResponse | null> {
        let id = localStorage.getItem('id');
        let token = localStorage.getItem('token');
        if(!id || !token) return null;
        
        const data = await axios.post(`${process.env.REACT_APP_STREAM_SERVER_URL}/spotify/browse/newReleases`, {
            id,
            token,  
        } satisfies Types.AuthedQuery).then(res => res.data).catch(() => null);
        if(!data) return null;
        return data.body;
    }
}