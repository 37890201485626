import { Link } from "react-router-dom";

function ArtistNames({ names, links }: { names: string[]; links: string[] }) {
  return <>
    {names.map((name, i) => {
    return (
      <span key={i}>
        <Link to={links[i]}>{name}</Link>
        {i !== names.length - 1 && ", "}
      </span>
    );
  })}
  </>
}

export default ArtistNames;
