import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import 'toastr/build/toastr.min.css'

// FONTS
import '@fontsource-variable/open-sans';
// Supports weights 300-700
import '@fontsource-variable/quicksand';
// Supports weights 100-900
import '@fontsource-variable/montserrat';

// create an mui theme
const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#54aaf0', // Blue
    },
    secondary: {
      main: '#245a50', // Darker Blue
    },
    background: {
      default: '#000000', // very dark gray
    },
    text: {
      primary: '#fff', // white
      secondary: '#b3b3b3', // light gray
    },
    action: {
      active: '#54aaf0', // Blue
    },
  },
  typography: {
    fontFamily: '"Quicksand Variable", sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '5px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#000000',
        },
      },
    },
    // give tooltips a zIndex of 10000
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          zIndex: 10000,
        },
        popper: {
          zIndex: 10000,
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingTop: '0px',
          paddingBottom: '0px',
        },
      },
    },

    MuiMenu: {
      styleOverrides: {
        root: {
          zIndex: 10000,
        }
      }
    },

    MuiTab: {
      styleOverrides: {
        // change the font
        root: {
          fontFamily: '"Montserrat Variable", sans-serif',
          fontWeight: 400,
          textTransform: 'none',
        },
      }
    }
  },
});


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </ThemeProvider>
);