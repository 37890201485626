import axios from "axios";
import { Types } from "./types";

export namespace YouTube {
    export async function getVideoById(videoID: string): Promise<Types.YoutubeInfo | null> {
        let id = localStorage.getItem('id');
        let token = localStorage.getItem('token');
        if(!id || !token) return null;

        const data = await axios.post(`${process.env.REACT_APP_STREAM_SERVER_URL}/youtube/video/${videoID}`, {
            id,
            token,
        } satisfies Types.AuthedQuery).then(res => res.data).catch(() => null);
        if(!data) return null;
        return data;
    }
}