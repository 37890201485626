import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Spotify } from "../spotify";
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { formatTime } from "../types";
import { favManager } from "../App";
import * as toastr from "toastr";

// components
import SearchBar from "../components/Searchbar";
import CoverIcon from "../components/CoverIcon";
import ClickableTrackIcon from "../components/ClickableTrackIcon";
import SessionManager from "../sessionManager";
import { MoreHoriz } from "@mui/icons-material";
import AlbumCard from "../components/AlbumCard";
import PageChip from "../components/PageChip";

function Search({ currSession }: { currSession: SessionManager | undefined }) {
  let { query } = useParams<{ query: string }>();

  const navigate = useNavigate();

  const [results, setResults] = useState<SpotifyApi.SearchResponse | null>(
    null
  );
  const [page, setPage] = useState(0);

  useEffect(() => {
    console.log(query);
    if (!query)
      return navigate("/search/" + localStorage.getItem("lastQuery") || "");
    setResults(null);

    (async () => {
      if (!query) return;
      const res = await Spotify.search({
        query: query,
        type: ["track", "artist", "album", "playlist"],
      });
      console.log(res);
      setResults(res);
    })();
  }, [query]);

  if (!results)
    return (
      <Backdrop open={true}>
        <CircularProgress />
      </Backdrop>
    );
  else
    return (
      <>
        <br />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            px: "10px",
          }}
        >
          <SearchBar
            value={query}
            style={{
              width: "100%",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "flex-start",
            mt: "10px",
            px: "10px",
            gap: "10px",
          }}
        >
          <PageChip
            title="All"
            onClick={() => setPage(0)}
            active={page === 0}
          />
          <PageChip
            title="Tracks"
            onClick={() => setPage(1)}
            active={page === 1}
          />
          <PageChip
            title="Albums"
            onClick={() => setPage(2)}
            active={page === 2}
          />
          <PageChip
            title="Artists"
            onClick={() => setPage(3)}
            active={page === 3}
          />
        </Box>
        {/* <Tabs
          variant="fullWidth"
          value={page}
          onChange={(event, newpage) => {
            setPage(newpage);
          }}
        >
          <Tab label="Tracks" />
          <Tab label="Albums" />
          <Tab label="Artists" />
          <Tab disabled label="Playlists" />
        </Tabs> */}

        <Divider
          sx={{
            width: "100%",
            my: "10px",
            mx: "10px",
          }}
        />

        {page === 0 && (
          <Box
            sx={{
              px: "10px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "50px",
            }}
          >
            {(results?.tracks?.items.length ?? 0) +
              (results?.artists?.items.length ?? 0) +
              (results?.albums?.items.length ?? 0) ===
              0 && <h4>No Results Found</h4>}
            <Box
              sx={{
                display: results?.tracks?.items.length === 0 ? "none" : "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  color: "#ffffff",
                  ml: "10px",
                }}
              >
                Tracks
              </Typography>

              <TableContainer component={Paper}>
                <Table
                  sx={{
                    width: "100%",
                    backgroundColor: "#000000",
                    border: "none",
                    "& .MuiTableRow-root": {
                      "&:hover": {
                        backgroundColor: "#121212",
                        transition: "0s",
                      },
                      transition: "0.5s",
                    },
                    "& .MuiTableCell-root": {
                      borderBottom: "none",
                      color: "#ffffff",
                    },
                  }}
                >
                  <TableHead></TableHead>
                  {results?.tracks?.items.slice(0, 5).map((track) => {
                    return (
                      <SearchTrackRow
                        key={track.id}
                        track={track}
                        currSession={currSession}
                      />
                    );
                  })}
                </Table>
              </TableContainer>
            </Box>

            <Box
              sx={{
                display: results?.artists?.items.length === 0 ? "none" : "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  color: "#ffffff",
                  ml: "10px",
                }}
              >
                Artists
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "10px",
                  width: "100%",
                  overflowX: "hidden",
                  px: "10px",
                  py: "10px",
                }}
              >
                {results?.artists?.items.slice(0, 15).map((artist) => {
                  return (
                    <CoverIcon
                      imageUrl={artist.images[0]?.url}
                      title={artist.name}
                      id={artist.id}
                      type="artist"
                    />
                  );
                })}
              </Box>
            </Box>

            <Box
              sx={{
                display: results?.albums?.items.length === 0 ? "none" : "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  color: "#ffffff",
                  ml: "10px",
                }}
              >
                Albums
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "10px",
                  width: "100%",
                  overflowX: "hidden",
                  px: "10px",
                  py: "10px",
                }}
              >
                {results?.albums?.items.slice(0, 15).map((album) => {
                  return (
                    <AlbumCard
                      albumName={album.name}
                      albumImage={album.images[0]?.url}
                      albumLink={`/album/${album.id}`}
                      albumExtra={`${album.artists[0].name} - ${album.album_type}`}
                      albumSpotifyLink={album.external_urls.spotify}
                      albumID={album.id}
                      artistName={album.artists[0].name}
                      artistID={album.artists[0].id}
                      currSession={currSession}
                      onPlayNow={() =>
                        currSession?.playAlbumNow(album.id).catch((err) => {
                          toastr.error(err.message);
                        })
                      }
                      onPlayNext={() =>
                        currSession?.playAlbumNext(album.id).catch((err) => {
                          toastr.error(err.message);
                        })
                      }
                      onAddToQueue={() =>
                        currSession?.addAlbumToQueue(album.id).catch((err) => {
                          toastr.error(err.message);
                        })
                      }
                    />
                  );
                })}
              </Box>
            </Box>
          </Box>
        )}

        {page === 1 && (
          <Box style={{ marginBottom: "100px" }}>
            <TableContainer component={Paper}>
              <Table
                sx={{
                  minWidth: 650,
                  backgroundColor: "#000000",
                  border: "none",
                  "& .MuiTableRow-root": {
                    "&:hover": {
                      backgroundColor: "#121212",
                      transition: "0s",
                    },
                    transition: "0.5s",
                  },
                  "& .MuiTableCell-root": {
                    borderBottom: "none",
                    color: "#ffffff",
                  },
                }}
              >
                <TableHead></TableHead>
                {results?.tracks?.items.length === 0 && (
                  <h4>No Results Found</h4>
                )}
                {results?.tracks?.items.map((track) => {
                  return (
                    <SearchTrackRow
                      key={track.id}
                      track={track}
                      currSession={currSession}
                    />
                  );
                })}
              </Table>
            </TableContainer>
          </Box>
        )}
        {page === 2 && (
          <Box style={{}}>
            <Grid
              container
              spacing={2}
              style={{ padding: "1rem", width: "100%", marginBottom: "100px" }}
              xl={12}
            >
              {results?.albums?.items.length === 0 && <h4>No Results Found</h4>}
              {results?.albums?.items.map((album) => {
                return (
                  <Grid item key={album.id}>
                    <AlbumCard
                      albumName={album.name}
                      albumImage={album.images[0]?.url}
                      albumLink={`/album/${album.id}`}
                      albumExtra={`${album.artists[0].name} - ${album.album_type}`}
                      albumSpotifyLink={album.external_urls.spotify}
                      albumID={album.id}
                      artistName={album.artists[0].name}
                      artistID={album.artists[0].id}
                      currSession={currSession}
                      onPlayNow={() =>
                        currSession?.playAlbumNow(album.id).catch((err) => {
                          toastr.error(err.message);
                        })
                      }
                      onPlayNext={() =>
                        currSession?.playAlbumNext(album.id).catch((err) => {
                          toastr.error(err.message);
                        })
                      }
                      onAddToQueue={() =>
                        currSession?.addAlbumToQueue(album.id).catch((err) => {
                          toastr.error(err.message);
                        })
                      }
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}
        {page === 3 && (
          <Box style={{}}>
            <Grid
              container
              spacing={2}
              style={{ padding: "1rem", width: "100%", marginBottom: "100px" }}
              xl={12}
            >
              {results?.artists?.items.length === 0 && (
                <h4>No Results Found</h4>
              )}
              {results?.artists?.items.map((artist) => {
                return (
                  <Grid item key={artist.id}>
                    <CoverIcon
                      imageUrl={artist.images[0]?.url}
                      title={artist.name}
                      id={artist.id}
                      type="artist"
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        )}
        <Typography
          sx={{
            fontSize: "15px",
            marginTop: "10px",
            marginBottom: "50px",
            alignSelf: "center",
            color: "#ffffffAA",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          All content is provided by{" "}
          <a
            style={{ fontWeight: "bold" }}
            href="http://spotify.com"
            target="_blank"
            rel="noreferrer"
          >
            Spotify
          </a>
        </Typography>
      </>
    );
}

export default Search;

const SearchTrackRow = ({
  track,
  currSession,
}: {
  track: SpotifyApi.TrackObjectFull;
  currSession: SessionManager | undefined;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [favorite, setFavorite] = useState<boolean>(favManager.has(track.id));

  return (
    <>
      <TableRow
        key={track.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell
          component="th"
          scope="row"
          width={50}
          sx={{
            padding: "0px",
          }}
        >
          <ClickableTrackIcon
            track={track}
            onClick={() => {
              if (!currSession?.SessionID) return;
              currSession.playNow(track.id);
            }}
          />
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          {track.name}
        </TableCell>
        <TableCell align="right">
          <Link to={`/artist/${track.artists[0].id}`}>
            {track.artists[0].name}
          </Link>
        </TableCell>
        <TableCell align="right">
          <Link to={`/album/${track.album.id}`}>{track.album.name}</Link>
        </TableCell>
        <TableCell align="right">
          {formatTime(Math.round(track.duration_ms / 1000))}
        </TableCell>
        <TableCell align="right">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setAnchorEl(e.currentTarget);
            }}
          >
            <MoreHoriz />
          </IconButton>
        </TableCell>
      </TableRow>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onClick={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            if (!currSession?.SessionID) return;
            currSession.playNext(track.id).catch((err) => {
              toastr.error(err.message);
            });
            console.log("added track");
          }}
        >
          Play Next
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (!currSession?.SessionID) return;
            currSession.addToQueue(track.id).catch((err) => {
              toastr.error(err.message);
            });
            console.log("added track");
          }}
        >
          Add to Queue
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => window.open(track.external_urls.spotify, "_blank")}
        >
          Open on Spotify
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (favorite) {
              favManager.remove(track.id);
              setFavorite(false);
            } else {
              favManager.add(track.id, {
                id: track.id,
                name: track.name,
                thumbnail: track.album.images[0].url,
                type: "track",
                artist: track.artists[0].name,
                artistID: track.artists[0].id,
                album: track.album.name,
                albumID: track.album.id,
              });
              setFavorite(true);
            }
          }}
        >
          {favorite ? "Remove from Favorites" : "Add to Favorites"}
        </MenuItem>
      </Menu>
    </>
  );
};
