import { Box, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import SessionManager from "../sessionManager";
import { MoreHoriz, PlayArrow } from "@mui/icons-material";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { favManager } from "../App";

function AlbumCard({
  albumName,
  albumImage,
  albumExtra,
  albumLink,
  albumID,
  albumSpotifyLink,
  artistName,
  artistID,
  currSession,
  onPlayNow,
  onPlayNext,
  onAddToQueue,
}: {
  albumName: string;
  albumImage: string;
  albumExtra: string;
  albumLink: string;
  albumID?: string;
  artistName: string;
  artistID: string;
  albumSpotifyLink?: string;
  currSession: SessionManager | undefined;
  onPlayNow?: () => void;
  onPlayNext?: () => void;
  onAddToQueue?: () => void;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const [favorite, setFavorite] = useState<boolean>(favManager.has(albumID || "placeholder"));

  return (
    <>
      <Menu open={open} onClose={() => setAnchorEl(null)} onClick={() => setAnchorEl(null)} anchorEl={anchorEl}>
        <MenuItem
          onClick={() => onPlayNext && onPlayNext()}
        >
          Play Next
        </MenuItem>
        <MenuItem
          onClick={() => onAddToQueue && onAddToQueue()}
        >
          Add to Queue
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{
            display: albumSpotifyLink ? "block" : "none",
          }}
          onClick={() => window.open(albumSpotifyLink, "_blank")}
        >
          Open in Spotify
        </MenuItem>
        <MenuItem
          sx={{
            display: albumID ? "block" : "none",
          }}
          onClick={() => {
            if (!albumID) return;
            if (favorite) {
              favManager.remove(albumID);
              setFavorite(false);
            } else {
              favManager.add(albumID, {
                name: albumName,
                type: "album",
                artist: artistName,
                album: albumName,
                id: albumID,
                albumID: albumID,
                artistID: artistID,
                thumbnail: albumImage,
              });
              setFavorite(true);
            }
          }}
        >
          {favorite ? "Remove from Favorites" : "Add to Favorites"}
        </MenuItem>
      </Menu>

      <Box
        sx={{
          width: "200px",
          height: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          margin: "10px",
          cursor: "pointer",
          transition: "transform 0.2s ease-in-out",
        }}
      >
        <Box
          sx={{
            width: "200px",
            height: "200px",
            objectFit: "cover",
            backgroundImage: `url(${albumImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            transition: "background-image 0.2s ease-in-out",
            "&:hover > div": {
              display: "grid",
            },

            borderRadius: "10px",
          }}
          onClick={() => navigate(albumLink)}
        >
          <Box
            sx={{
              display: "none",
              width: "100%",
              height: "100%",
              backgroundColor: "#00000055",
              backdropFilter: "brightness(0.5)",
              transition: "background-color 0.2s ease-in-out",
              gridTemplateColumns: "1fr 1fr 1fr",
              gridTemplateRows: "1fr 1fr 1fr",
              gridTemplateAreas: `
                ". . ."
                ". play ."
                ". . opt"
            `,
            }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onPlayNow && onPlayNow();
              }}
              sx={{
                color: "#FFFFFF",
                gridArea: "play",
              }}
            >
              <PlayArrow />
            </IconButton>
            <IconButton
              sx={{
                color: "#FFFFFF",
                gridArea: "opt",
                padding: "3px",
                width: "32px",
                height: "32px",
                alignSelf: "flex-end",
                justifySelf: "flex-end",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setAnchorEl(e.currentTarget)
              }}
            >
              <MoreHoriz
                sx={{
                  fontSize: "20px",
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            textAlign: "left",
            marginTop: "10px",
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              color: "#FFFFFF",
              // all text one line
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              textAlign: "left",

              width: "200px"
            }}
          >
            <Link to={albumLink}>{albumName}</Link>
          </Box>
          <Box
            sx={{
              fontSize: "12px",
              color: "#FFFFFF",
              textAlign: "left",
            }}
          >
            {albumExtra}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default AlbumCard;
