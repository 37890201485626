import { Backdrop, Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { Types } from '../types'

function Auth() {
    const [loginFailData, setLoginFailData] = useState(null as Types.APIError | null)

    useEffect(() => {
        setTimeout(async () => {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/callback/getData`, {
                code: new URLSearchParams(window.location.search).get('code')
            }).catch((err) => {
                return err.response
            })

            if (response.status !== 200) {
                setLoginFailData({
                    code: response.status,
                    type: response.data.type,
                    message: response.data.message
                } as Types.APIError)
            }
            else {
                localStorage.setItem('id', (response.data as Types.User).id)
                localStorage.setItem('token', (response.data as Types.User).authToken)
                localStorage.setItem('username', (response.data as Types.User).username)
                localStorage.setItem('avatarURL', (response.data as Types.User).avatarURL)
                window.location.href = '/'
            }
        }, 1000)
    }, [])

    if(!loginFailData) {
        return (
            <Box>
                <Backdrop 
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <Stack direction='column' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <h1>Logging in</h1>
                        <CircularProgress />
                    </Stack>
                </Backdrop>
            </Box>
          )
    } else {
        return (
            <Box>
                <Backdrop 
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <Stack direction='column' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <h1>Login Failed</h1>
                        <h2>{loginFailData.code}</h2>
                        <Typography>{loginFailData.type}</Typography>
                        <Typography>{loginFailData.message}</Typography>

                        <Button variant='contained' style={{ marginTop: '25px' }} onClick={() => window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/web/discord`}>Retry</Button>
                    </Stack>
                </Backdrop>
            </Box>
        )
    }
}

export default Auth