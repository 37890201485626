import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import SessionManager from "../sessionManager";
import {
  CellTower,
  Favorite,
  FavoriteBorder,
  MoreHoriz,
  PlayArrow,
  PlaylistAdd,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Spotify } from "../spotify";
import { useParams } from "react-router-dom";
import { favManager } from "../App";
import * as toastr from "toastr";

// components
import TopTracksRow from "../components/TopTracksRow";
import SpotifyIcon from "../components/SpotifyIcon";
import AlbumCard from "../components/AlbumCard";
import CoverIcon from "../components/CoverIcon";

function Artist({ currSession }: { currSession: SessionManager | undefined }) {
  const { id } = useParams<{ id: string }>();

  const [artistData, setArtistData] =
    useState<SpotifyApi.SingleArtistResponse | null>(null);
  const [artistTopTracks, setArtistTopTracks] =
    useState<SpotifyApi.ArtistsTopTracksResponse | null>(null);
  const [artistAlbums, setArtistAlbums] =
    useState<SpotifyApi.ArtistsAlbumsResponse | null>(null);
  const [relatedArtists, setRelatedArtists] =
    useState<SpotifyApi.ArtistsRelatedArtistsResponse | null>(null);

  const [favourite, setFavourite] = useState<boolean>(false);

  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    if (id === undefined) return;

    setFavourite(favManager.has(id));

    setArtistData(null);
    setArtistTopTracks(null);
    setArtistAlbums(null);
    setRelatedArtists(null);

    Spotify.getArtist(id).then(async (res) => {
      setArtistData(res);
      if (!res) return;
      const topTracks = await Spotify.getArtistTopTracks(res.id);
      setArtistTopTracks(topTracks);
      const albums = await Spotify.getArtistAlbums(res.id);
      setArtistAlbums(albums);
      const related = await Spotify.getArtistRelatedArtists(res.id);
      setRelatedArtists(related);
    });
  }, [id]);

  const [artistOptionsMenuAnchor, setArtistOptionsMenuAnchor] =
    useState<null | HTMLElement>(null);
  const artistOptionsMenuOpen = Boolean(artistOptionsMenuAnchor);

  if (artistData === null)
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "flex-start",
          width: "100%",
          height: "40vh",
          padding: "40px",
          position: "relative",

          // apply to ::before
          "&::before": {
            content: "''",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${artistData?.images[0]?.url})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundBlendMode: "darken",
            backgroundColor: "#000000AA",
            boxShadow: "0px 2px 5px 2px #00000055",
            borderRadius: "50px",
            filter: "blur(10px)",
            zIndex: -1,
          },
        }}
      >
        <Avatar
          src={artistData?.images[0]?.url}
          variant="circular"
          sx={{ width: "200px", height: "200px" }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            marginLeft: "40px",
            height: "200px",
            position: "relative",
            userSelect: "none",
            cursor: "default",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Open Sans Variable, sans-serif",
              fontSize: "4rem",
              fontWeight: "bold",
            }}
          >
            {artistData?.name}
          </Typography>
          <Typography
            sx={{
              fontSize: "2rem",
              color: "#ffffffAA",
              position: "absolute",
              bottom: "0",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {artistData?.genres.join(", ")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginLeft: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "20px",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "30px",
            }}
          >
            <Box
              sx={{
                width: "50px",
                color: "black",
                aspectRatio: "1/1",
                borderRadius: "50%",
                background: (theme) => theme.palette.primary.main,
                boxShadow: "0px 2px 5px 2px #00000055",
                cursor: "pointer",

                "&:hover": {
                  backgroundColor: (theme) => theme.palette.primary.dark,
                },

                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => {
                if (!currSession) return;
                let trackIds: string[] = [];
                artistTopTracks?.tracks.forEach((t) => trackIds.push(t.id));
                if (!(trackIds.length > 0)) return;
                currSession.playNow(trackIds.slice(0, 25)).catch((err) => {
                  toastr.error(err.message);
                });
              }}
            >
              <PlayArrow
                sx={{
                  fontSize: "30px",
                }}
              />
            </Box>
            <Tooltip title="Open on Spotify">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  cursor: "pointer",
                  width: "40px",
                  height: "40px",
                }}
                onClick={() => window.open(artistData?.external_urls.spotify)}
              >
                <SpotifyIcon color="white" />
              </Box>
            </Tooltip>
            <Tooltip
              title={`${favourite ? "Remove from" : "Add to"} favourites`}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  cursor: "pointer",
                  width: "40px",
                  height: "40px",
                }}
                onClick={() => {
                  if (!artistData) return;
                  if (favourite) {
                    favManager.remove(artistData.id);
                    setFavourite(false);
                  } else {
                    favManager.add(artistData.id, {
                      name: artistData.name,
                      type: "artist",
                      artist: artistData.name,
                      id: artistData.id,
                      artistID: artistData.id,
                      thumbnail: artistData.images[0]?.url,
                    });
                    setFavourite(true);
                  }
                }}
              >
                {favourite ? (
                  <Favorite sx={{ color: "white" }} />
                ) : (
                  <FavoriteBorder sx={{ color: "white" }} />
                )}
              </Box>
            </Tooltip>
            <Tooltip title="Add to Queue">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  cursor: "pointer",
                  width: "40px",
                  height: "40px",
                }}
                onClick={() => {
                  if (
                    !currSession ||
                    !artistTopTracks ||
                    artistTopTracks.tracks.length === 0
                  )
                    return;
                  currSession
                    .addToQueue(
                      artistTopTracks?.tracks.map((track) => track.id)
                    )
                    .catch((err) => {
                      toastr.error(err.message);
                    });
                }}
              >
                <PlaylistAdd sx={{ color: "white" }} />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Tabs value={page} onChange={(_, newValue) => setPage(newValue)}>
          <Tab label="Home" />
          <Tab label="Albums" />
          <Tab label="Singles" />
          <Tab label="Related Artists" />
        </Tabs>
        <Divider />
      </Box>

      {page === 0 && (
        <Box
          sx={{
            width: "100%",
            height: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginTop: "40px",
            pr: "40px",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Top Tracks
          </Typography>
          <Table
            sx={{
              width: "100%",
              size: "small",
              border: "none",
              marginTop: "10px",
              borderTop: "1px solid gray",
              borderBottom: "1px solid gray",
              "& .MuiTableCell-root": {
                border: "none",
                fontSize: "16px",
                padding: "5px",
              },
            }}
          >
            <TableBody>
              {artistTopTracks === null && (
                <TableRow>
                  <TableCell
                    sx={{
                      border: "none",
                      fontSize: "16px",
                      padding: "5px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    colSpan={4}
                  >
                    <CircularProgress color="inherit" />
                  </TableCell>
                </TableRow>
              )}
              {artistTopTracks?.tracks.length === 0 && (
                <TableRow>
                  <TableCell
                    sx={{
                      border: "none",
                      fontSize: "16px",
                      padding: "5px",
                    }}
                    colSpan={4}
                  >
                    No Tracks Found
                  </TableCell>
                </TableRow>
              )}
              {artistTopTracks?.tracks.map((track, index) => (
                <TopTracksRow
                  key={index}
                  index={index + 1}
                  trackName={track.name}
                  trackId={track.id}
                  albumName={track.album.name}
                  albumId={track.album.id}
                  albumLink={`/album/${track.album.id}`}
                  spotifyLink={track.external_urls.spotify}
                  artistName={track.artists[0].name}
                  artistId={track.artists[0].id}
                  thumbnail={track.album.images[0].url}
                  currSession={currSession}
                  duration={Math.floor(track.duration_ms / 1000)}
                  onPlayNow={() =>
                    currSession?.playNow(track.id).catch((err) => {
                      toastr.error(err.message);
                    })
                  }
                  onPlayNext={() =>
                    currSession?.playNext(track.id).catch((err) => {
                      toastr.error(err.message);
                    })
                  }
                  onAddToQueue={() =>
                    currSession?.addToQueue(track.id).catch((err) => {
                      toastr.error(err.message);
                    })
                  }
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      )}

      {page === 1 && (
        <Box
          sx={{
            width: "100%",
            height: "auto",
            display:
              artistAlbums?.items.filter(
                (album) => album.album_type === "album"
              ).length === 0
                ? "none"
                : "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "auto",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              marginTop: "20px",
            }}
          >
            {artistAlbums === null && (
              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
            {artistAlbums?.items
              .filter((album) => album.album_type === "album")
              .map((album, index) => (
                <AlbumCard
                  key={index}
                  albumName={album.name}
                  albumImage={album.images[0].url}
                  albumExtra={album.release_date.split("-")[0]}
                  albumLink={`/album/${album.id}`}
                  albumSpotifyLink={album.external_urls.spotify}
                  albumID={album.id}
                  artistName={album.artists[0].name}
                  artistID={album.artists[0].id}
                  currSession={currSession}
                  onPlayNow={() =>
                    currSession?.playAlbumNow(album.id).catch((err) => {
                      toastr.error(err.message);
                    })
                  }
                  onPlayNext={() =>
                    currSession?.playAlbumNext(album.id).catch((err) => {
                      toastr.error(err.message);
                    })
                  }
                  onAddToQueue={() =>
                    currSession?.addAlbumToQueue(album.id).catch((err) => {
                      toastr.error(err.message);
                    })
                  }
                />
              ))}
          </Box>
        </Box>
      )}

      {page === 2 && (
        <Box
          sx={{
            width: "100%",
            height: "auto",
            display:
              artistAlbums?.items.filter(
                (album) => album.album_type === "single"
              ).length === 0
                ? "none"
                : "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "auto",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              marginTop: "20px",
            }}
          >
            {artistAlbums === null && (
              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
            {artistAlbums?.items
              .filter((album) => album.album_type === "single")
              .map((album, index) => (
                <AlbumCard
                  key={index}
                  albumName={album.name}
                  albumImage={album.images[0].url}
                  albumExtra={album.album_type}
                  albumLink={`/album/${album.id}`}
                  albumID={album.id}
                  artistName={album.artists[0].name}
                  artistID={`/artist/${album.artists[0].id}`}
                  albumSpotifyLink={album.external_urls.spotify}
                  currSession={currSession}
                  onPlayNow={() =>
                    currSession?.playAlbumNow(album.id).catch((err) => {
                      toastr.error(err.message);
                    })
                  }
                  onPlayNext={() =>
                    currSession?.playAlbumNext(album.id).catch((err) => {
                      toastr.error(err.message);
                    })
                  }
                  onAddToQueue={() =>
                    currSession?.addAlbumToQueue(album.id).catch((err) => {
                      toastr.error(err.message);
                    })
                  }
                />
              ))}
          </Box>
        </Box>
      )}

      {page === 3 && (
        <Box
          sx={{
            width: "100%",
            height: "auto",
            display: relatedArtists?.artists.length === 0 ? "none" : "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "auto",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              marginTop: "20px",
              gap: "20px",
            }}
          >
            {relatedArtists === null && (
              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="inherit" />
              </Box>
            )}
            {relatedArtists?.artists.map((artist, index) => (
              <CoverIcon
                key={index}
                imageUrl={artist.images[0]?.url}
                title={artist.name}
                id={artist.id}
                type="artist"
              />
            ))}
          </Box>
        </Box>
      )}

      <Typography
        sx={{
          fontSize: "15px",
          marginTop: "50px",
          marginBottom: "50px",
          alignSelf: "center",
          color: "#ffffffAA",
        }}
      >
        All content is provided by{" "}
        <a
          style={{ fontWeight: "bold" }}
          href="http://spotify.com"
          target="_blank"
          rel="noreferrer"
        >
          Spotify
        </a>
      </Typography>
    </Box>
  );
}

export default Artist;
