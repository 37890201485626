import {
  Alert,
  Backdrop,
  Box,
  Button,
  Collapse,
  Switch,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { error } from "toastr";
import { socket } from "../App";
import SessionManager from "../sessionManager";
import { Types } from "../types";

function SessionSettingsPopup({
  session,
  openHook: [open, setOpen],
}: {
  session: SessionManager | undefined;
  openHook: [boolean, (open: boolean) => void];
}) {
  const [sessionSettings, setSessionSettings] = useState<{
    hostOnly: boolean;
    allowSkip: boolean;
  }>({
    hostOnly: session?.settings.hostOnly ?? false,
    allowSkip: session?.settings.allowSkip ?? true,
  });
  return (
    <Backdrop
      open={open}
      onClick={(e) => {
        // check whether the click was outside the popup
        if (e.target === e.currentTarget) setOpen(false);
      }}
      sx={{ zIndex: 5000 }}
    >
      <Box
        style={{
          backgroundColor: "#202020",
          padding: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
        }}
      >
        <Typography
          style={{
            color: "white",
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          Lets customize your session! 🚀
        </Typography>

        <Box
          sx={{
            mt: "20px",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "15px",
          }}
        >
          <Switch
            inputProps={{ "aria-label": "controlled" }}
            checked={sessionSettings.hostOnly}
            onChange={(e) => {
              setSessionSettings({
                ...sessionSettings,
                hostOnly: e.target.checked,
              });
            }}
          />
          <Box>
            <Typography
              sx={{
                color: "white",
                fontSize: "1rem",
              }}
            >
              Host only
            </Typography>
            <Typography
              sx={{
                color: "white",
                fontSize: "0.75rem",
              }}
              variant="caption"
            >
              Only the host can control the session
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            mt: "10px",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "15px",
          }}
        >
          <Switch
            inputProps={{ "aria-label": "controlled" }}
            checked={sessionSettings.allowSkip}
            disabled={sessionSettings.hostOnly}
            onChange={(e) => {
              setSessionSettings({
                ...sessionSettings,
                allowSkip: e.target.checked,
              });
            }}
          />
          <Box>
            <Typography
              sx={{
                color: "white",
                fontSize: "1rem",
              }}
            >
              Allow Skipping
            </Typography>
            <Typography
              sx={{
                color: "white",
                fontSize: "0.75rem",
              }}
              variant="caption"
            >
              Allow users to skip tracks and use play now
            </Typography>
          </Box>
        </Box>

        <Button
          sx={{
            mt: "20px",
          }}
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            socket.emit(
              "session_updateSettings",
              sessionSettings,
              (res: Types.SessionJoinResponse | Types.SocketError) => {
                setOpen(false);
              }
            );
          }}
        >
          SAVE
        </Button>
      </Box>
    </Backdrop>
  );
}

export default SessionSettingsPopup;
