import { Avatar, Box, Divider, IconButton, Typography } from "@mui/material";
import SessionManager from "../sessionManager";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ImageToGradient from "../extra/ImageToGradient";
import { Fullscreen, FullscreenExit } from "@mui/icons-material";

// states
import useUI from "../states/UIStates";

function Playback({
  currSession,
}: {
  currSession: SessionManager | undefined;
}) {
  // For forcing a re-render
  const [currTime, setCurrTime] = useState<number>(0);

  const { windowFullScreen, setWindowFullScreen } = useUI();

  useEffect(() => {
    if (!currSession) return;
    const listener = () => {
      setCurrTime(Date.now());
      (async () => {
        if (currSession?.queue.current()?.trackID) {
          // turn the current track thumbnail into a gradient and set it as the background
          if (currSession?.queue.current()?.thumbnail) {
            if (!currSession?.queue.current()) return;
            const gradient = await ImageToGradient(
              currSession.queue.current()?.thumbnail || ""
            );
            // set the css var --accent-color to the gradient
            document.documentElement.style.setProperty(
              "--accent-color",
              `rgba(${gradient.r}, ${gradient.g}, ${gradient.b}, 1)`
            );
          }
        }
      })();
    };
    listener();
    currSession.on("playback_next", listener);
    currSession.on("playback_start", listener);
    currSession.on("queue_update", listener);
    return () => {
      currSession.off("playback_next", listener);
      currSession.off("playback_start", listener);
      currSession.off("queue_update", listener);
    };
  }, [currSession]);

  if (!currSession) return <h1>Nothing Playing</h1>;
  return (
    <Box
      sx={{
        width: windowFullScreen ? "100vw" : "100%",
        height: windowFullScreen ? "100vh" : "100%",
        marginTop: windowFullScreen ? "-70px" : "-5px",
        marginLeft: windowFullScreen ? ( "-200px") : "0px",
        paddingBottom: "5%",
        display: "grid",
        gridTemplateColumns: currSession.queue.has(1) ? "1fr 5px 1fr" : "1fr",
        alignItems: "center",
        background: "transparent",
        gap: "30px",
        "@media (max-width: 1500px)": {
          gridTemplateColumns: "1fr",
          paddingTop: "30px",
          gap: "0px",
        },
        transition: "all 0.5s ease-in-out",
      }}
    >
      <Box sx={{
        position: "absolute",
        top: "0px",
        left: "0px",
        width: "100vw",
        height: "100vh",
        background: "linear-gradient(130deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.6) 100%), var(--accent-color)",
        zIndex: -1,
        filter: "blur(50px)",
        transition: "all 1.5s ease-in-out",
      }}></Box>
      <IconButton sx={{
        position: "absolute",
        top: windowFullScreen ? "25px" : "80px",
        right: "25px",
        color: "white",
        opacity: 0.5,
        zIndex: 1000,
        "@media (max-width: 1500px)": {
          top: "0px",
          right: "0px",
        },
        "&:hover": {
          opacity: 1,
        },
        transition: "all 0.5s ease-in-out",
      }} onClick={() => {
        if (windowFullScreen) {
          document.exitFullscreen();
          setWindowFullScreen(false);
        } else {
          document.documentElement.requestFullscreen();
          setWindowFullScreen(true);
        }
      }}>
        <Fullscreen sx={{
          position: "absolute",
          transform: windowFullScreen ? "rotate(0deg)" : "rotate(180deg)",
          transition: "transform 0.5s ease-in-out",
          opacity: windowFullScreen ? 0 : 1,
        }} />
        <FullscreenExit sx={{
          position: "absolute",
          transform: windowFullScreen ? "rotate(180deg)" : "rotate(0deg)",
          transition: "transform 0.5s ease-in-out",
          opacity: windowFullScreen ? 1 : 0,
        }} /> 
      </IconButton>

      <Box
        sx={{
          height: "auto",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "30px",
          transition: "all 0.2s ease-in-out",
          "@media (max-width: 1500px)": {
            flexDirection: "column",
            gap: "10px",
            textAlign: "center",
          },
        }}
      >
        <Avatar
          src={currSession?.queue.has(0) ? currSession?.queue.current()?.thumbnail : `${process.env.PUBLIC_URL}/logo.png`}
          variant="square"
          sx={{
            width: "400px",
            height: "400px",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "10px",
            "@media (max-width: 1500px)": {
              alignItems: "center",
              justifyContent: "center",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              maxWidth: "600px",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {currSession.queue.current()?.title}
          </Typography>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            "@media (max-width: 1500px)": {
              alignItems: "center",
              justifyContent: "center",
            },
          }}>
            {currSession.queue.current()?.albumID === "youtube" ? (
              <>
                <Typography sx={{}}>
                  {currSession.queue.current()?.artist}
                </Typography>
                <Typography> {currSession.queue.current()?.album} </Typography>
              </>
            ) : (
              <>
                <Link to={`/artist/${currSession.queue.current()?.artistID}`}>
                  <Typography sx={{}}>
                    {currSession.queue.current()?.artist}
                  </Typography>
                </Link>
                <Link to={`/album/${currSession.queue.current()?.albumID}`}>
                  <Typography>
                    {" "}
                    {currSession.queue.current()?.album}{" "}
                  </Typography>
                </Link>
              </>
            )}
          </Box>
        </Box>
      </Box>

      <Divider
        orientation="vertical"
        sx={{
          height: "400px",
          maxHeight: "400px",
          width: "5px",
          maxWidth: "5px",
          color: "#fff",
          display: currSession.queue.has(1) ? "block" : "none",
          "@media (max-width: 1500px)": {
            display: "none",
          },
          transition: "all 0.2s ease-in-out",
        }}
      />

      <Box
        sx={{
          display: currSession.queue.has(1) ? "flex" : "none",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "10px",
          height: "600px",
          maxHeight: "600px",
          width: "95%",
          "@media (max-width: 1500px)": {
            height: "auto",
            maxHeight: "auto",
            mx: "30px",
          },
          transition: "all 0.2s ease-in-out",
          transform: currSession.queue.has(1) ? "translateX(0%)" : "translateX(-100%)",
        }}
      >
        <Typography
          sx={{
            fontSize: "2rem",
            fontWeight: "bold",
          }}
        >
          Up Next:
        </Typography>
        <Divider
          sx={{
            width: "100%",
            color: "#fff",
          }}
        />
        <Box
          sx={{
            width: "100%",
            height: "100%",
            overflowY: "scroll",
            overflowX: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "10px",
          }}
        >
          {currSession.queue.getItems().map((item, index) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "30px",
                    minWidth: "30px",
                    height: "50px",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    {index + 1}
                  </Typography>
                </Box>
                <Avatar
                  src={item.thumbnail}
                  variant="square"
                  sx={{
                    width: "50px",
                    height: "50px",
                  }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "bold",
                      maxWidth: "700px",
                      width: "700px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography sx={{}}>
                    {item.albumID === "youtube" ? (
                      <>
                        {item.artist} - {item.album}
                      </>
                    ) : (
                      <>
                        <Link to={`/artist/${item.artistID}`}>
                          {item.artist}
                        </Link>{" "}
                        -{" "}
                        <Link to={`/album/${item.albumID}`}>{item.album}</Link>
                      </>
                    )}
                  </Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default Playback;
