import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import SocketIO from "socket.io-client";
import { useEffect, useRef, useState, memo } from "react";
import SessionManager from "./sessionManager";
import ReactPlayer from "react-player";
import { dist } from "./types";
import FavouritesManager from "./favouritesManager";

// pages
import LoginPage from "./pages/Login";
import AuthLoginPage from "./pages/AuthLogin";
import JoinSessionPage from "./pages/JoinSession";
import HomePage from "./pages/Home";
import SearchPage from "./pages/Search";
import ArtistPage from "./pages/Artist";
import AlbumPage from "./pages/Album";
import FavouritesPage from "./pages/Favourites";
import YoutubePage from "./pages/Youtube";
import PlaybackPage from "./pages/Playback";

// components
import PlayBackBar from "./components/PlaybackBar";
import Sidebar from "./components/Sidebar";
import { Box } from "@mui/material";
import Navbar from "./components/Navbar";
import SessionPopup from "./components/SessionPopup";

// states
import useUI from "./states/UIStates";
import ToastManager from "./components/ToastManager";
import Session from "./pages/Session";


const socket = SocketIO(
  process.env.REACT_APP_STREAM_SERVER_URL || "http://localhost:1935",
  {
    auth: {
      id: localStorage.getItem("id"),
      token: localStorage.getItem("token"),
    },
  }
);
const favManager = new FavouritesManager();

export { socket, favManager };

function App() {
  const [currSession, setCurrSession] = useState<SessionManager>();

  const player = useRef<ReactPlayer>(null);

  return (
    <>
      <SessionPopup currSessionProp={[currSession, setCurrSession]} />
      <BrowserRouter>
        <PlayBackBar
          player={player}
          socket={socket}
          currSessionHook={[currSession, setCurrSession]}
        />
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            height: "100vh",
            width: "100vw",
          }}
        >
          <Navbar session={currSession} />
          <ToastManager />
          <Sidebar />

          <Box
            style={{
              paddingLeft: "200px",
              marginTop: "70px",
              transition: "all 0.2s ease-in-out",
              width: "100%",
            }}
          >
            <Routes>
              <Route
                path="/"
                element={<HomePage currSession={currSession} />}
              />
              <Route
                path="/join/:id"
                element={
                  <JoinSessionPage
                    currSessionProp={[currSession, setCurrSession]}
                  />
                }
              />
              <Route
                path="/search/:query?"
                element={<SearchPage currSession={currSession} />}
              />
              <Route
                path="/artist/:id"
                element={<ArtistPage currSession={currSession} />}
              />
              <Route
                path="/album/:id"
                element={<AlbumPage currSession={currSession} />}
              />
              <Route
                path="/youtube/:id"
                element={<YoutubePage currSession={currSession} />}
              />
              <Route
                path="/favorites"
                element={<FavouritesPage currSession={currSession} />}
              />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/auth/login" element={<AuthLoginPage />} />
              <Route path="/playback" element={<PlaybackPage currSession={currSession} />} />
              <Route path="/session" element={<Session />} />
            </Routes>
          </Box>
        </Box>
      </BrowserRouter>
    </>
  );
}

export default App;