import axios, { AxiosError } from "axios";
import * as toastr from "toastr";
import { Types } from "./types";
import EventEmitter from "events";

export type FavouriteType = 'track' | 'album' | 'artist' | 'youtube';

export interface Favourite {
    id: string;
    type: FavouriteType;
    name: string;
    thumbnail: string;
    artist?: string;
    artistID?: string;
    album?: string;
    albumID?: string;
    addedAT?: number;
}

export default class FavouritesManager extends EventEmitter {
    private favorites: Map<string, Favourite> = new Map();

    constructor() {
        super();
        this.loadFavourites();
    }

    public async loadFavourites() {
        const favs = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/favourites/get`, {
            id: localStorage.getItem('id'),
            token: localStorage.getItem('token')
        } as Types.AuthedQuery).catch((err) => {
            toastr.error("Failed to load favourites. " + err.data.message);
            console.error(err);
            Promise.reject();
        });
        if(!favs) return;

        if(favs.data.status === "success") {
            for(const fav of favs.data.favourites) {
                this.favorites.set(fav.favID, {
                    id: fav.favID,
                    type: fav.type,
                    name: fav.name,
                    thumbnail: fav.thumbnail,
                    artist: fav.artist,
                    artistID: fav.artistID,
                    album: fav.album,
                    albumID: fav.albumID,
                    addedAT: new Date(fav.addedAT).getTime()
                });
            }
            console.log("Loaded favourites.");
            console.log(this.favorites);
        }
        this.emit("ready")
        Promise.resolve();
    }

    // public saveFavourites() {
    //     const favourites = [];
    //     for(const fav of this.favorites.values()) {
    //         favourites.push(fav);
    //     }
    //     localStorage.setItem('favourites', JSON.stringify(favourites));
    // }

    public getAll(reversed: boolean = false) {
        const favs = [];
        for(const fav of this.favorites.values()) {
            favs.push(fav);
        }
        favs.sort((a, b) => {
            if(a.addedAT && b.addedAT) {
                return b.addedAT - a.addedAT;
            }
            return 0;
        });
        console.log(favs);
        if(reversed) favs.reverse();
        return favs;
    }

    public get(id: string) {
        return this.favorites.get(id);
    }

    public async add(id: string, data: Favourite) {
        this.favorites.set(id, {
            ...data,
            addedAT: Date.now()
        });
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/favourites/add/${id}`, {
            id: localStorage.getItem('id'),
            token: localStorage.getItem('token'),

            type: data.type,
            name: data.name,
            thumbnail: data.thumbnail,
            artist: data.artist,
            artistID: data.artistID,
            album: data.album,
            albumID: data.albumID,
        } as Favourite & Types.AuthedQuery).catch((err: AxiosError) => {
            toastr.error((err.response?.data as any).message, "Failed to add favourite.");
            console.error(err);
        })
        this.emit("add", data);
        this.emit("data_updated");
    }

    public remove(id: string) {
        this.favorites.delete(id);
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/favourites/remove/${id}`, {
            id: localStorage.getItem('id'),
            token: localStorage.getItem('token')
        } as Types.AuthedQuery).catch((err) => {
            toastr.error("Failed to remove favourite. " + err.data.message);
            console.error(err);
        })
        this.emit("remove", id);
        this.emit("data_updated");
    }

    public clear() {
        this.favorites.clear();
        return
    }

    public has(id: string) {
        return this.favorites.has(id);
    }

}