import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Divider,
  IconButton,
  LinearProgress,
  Popover,
  Popper,
  Slider,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ArrowBackIosNew,
  Close,
  Favorite,
  FavoriteBorder,
  MusicNote,
  OpenInFull,
  Pause,
  PlayArrow,
  Shuffle,
  ShuffleOn,
  SkipNext,
  SkipPrevious,
  Star,
  Stop,
  Timelapse,
  YouTube as YoutubeIcon,
} from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { Types, dist, formatTime } from "../types";
import SessionManager from "../sessionManager";
import QueueButton from "./QueueButton";
import { Socket } from "socket.io-client";
import ReactPlayer from "react-player";
import * as toastr from "toastr";
import { youtubeErrorConverter } from "../types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { favManager } from "../App";

// states
import usePlayback from "../states/PlaybackState";
import useUI from "../states/UIStates";
import PageChip from "./PageChip";
import usePlaybackDebugStats from "../states/PlaybackDebugStats";
import SpotifyIcon from "./SpotifyIcon";

function PlayBackBar({
  player,
  currSessionHook,
  socket,
}: {
  player: React.MutableRefObject<ReactPlayer | null>;
  currSessionHook: [
    SessionManager | undefined,
    React.Dispatch<React.SetStateAction<SessionManager | undefined>>
  ];
  socket: Socket;
}) {
  const [url, setUrl] = useState<string>("");
  const {
    playbackBarOpen,
    setPlaybackBarOpen,

    bigscreenViewOpen,
    setBigscreenViewOpen,
  } = useUI();
  const [currSession] = currSessionHook;
  const {
    playing,
    autoplay,
    setPlaying,
    togglePlaying,
    setAutoplay,
    toggleAutoplay,
  } = usePlayback();

  const [bigScreenVideoMode, setBigScreenVideoMode] = useState<boolean>(false);

  const [bigScreenPage, setBigScreenPage] = useState<number>(0);

  const [progress, setProgress] = useState<number>(0);
  const [bufferProgress, setBufferProgress] = useState<number>(0);
  const [volume, setVolume] = useState<number>(
    parseFloat((localStorage.getItem("volume") as string) || "50") / 100
  );

  const [favorite, setFavorite] = useState<boolean>(false);

  const [specialErrorText, setSpecialErrorText] = useState<string | null>(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!currSession?.SessionID) return;
    console.log(location.pathname);
  }, [location]);

  useEffect(() => {
    if (!currSession?.SessionID) return;
    console.log("Currsession has " + currSession.queue.has(0));
    setPlaybackBarOpen(currSession.queue.has(0));

    (window as any).getPlayBackInfo = () => {
      return {
        playback: {
          ...currSession.playback,
          time: player.current?.getCurrentTime(),
        },
        queue: currSession.queue.getQueue(),
        members: currSession.users,
      };
    };

    setAutoplay(currSession.playback.autoplay);

    socket.on("disconnect", () => {
      setSpecialErrorText("Server connection interrupted!");
      setPlaying(false);
      socket.once("reconnect", () => {
        setSpecialErrorText(null);
      });
    });
  }, [currSession]);

  useEffect(() => {
    if (!currSession?.SessionID) return;
    setFavorite(favManager.has(currSession?.queue.current()?.trackID || ""));
  }, [playing]);

  useEffect(() => {
    setBigscreenViewOpen(false);
  }, [playbackBarOpen]);

  useEffect(() => {
    if (document.fullscreenElement) document.exitFullscreen();
  }, [bigscreenViewOpen]);

  useEffect(() => {
    if (!currSession?.SessionID) return;
    setUrl(currSession.queue.current()?.url || "");
    setPlaybackBarOpen(currSession.queue.has(0));

    currSession.on("playback_start", (data) => {
      console.log("playback_start received");
      console.log(data);
      setPlaying(!data.paused);
      player.current?.seekTo(data.time);
    });

    currSession.on("playback_resync", (data: Types.PlaybackState) => {
      const progress = player.current?.getCurrentTime();
      if (!progress) return;

      setAutoplay(data.autoplay);

      if (dist(progress, data.time) > 2) player.current?.seekTo(data.time);
      if (!playing !== data.paused) {
        if (data.paused) setPlaying(false);
        else setPlaying(true);
      }
      console.log(progress);
      console.log(data.time);

      usePlaybackDebugStats
        .getState()
        .setDelay(Math.abs(progress - data.time) * 1000);
    });

    currSession.on("playback_resume", () => {
      console.log("playback_resume received");
      setPlaying(true);
    });
    currSession.on("playback_pause", () => {
      console.log("playback_pause received");
      setPlaying(false);
    });

    currSession.on("playback_seek", (data) => {
      console.log("playback_seek received");
      player.current?.seekTo(data);
    });

    currSession.on("playback_next", async () => {
      console.log("playback_next received");
      player.current?.seekTo(0);
      setUrl(currSession.queue.current()?.url || "");
    });
    currSession.on("playback_end", () => {
      console.log("playback_end received");
      setPlaying(false);
      setPlaybackBarOpen(false);
      setUrl("");
      currSession.playback.paused = true;
    });

    currSession.on("queue_update", (data) => {
      console.log("queue_update received");
      console.log(data);
      setUrl(currSession.queue.current()?.url || "");
      if (currSession.queue.has(0) && currSession.playback.paused) {
        setUrl(currSession.queue.current()?.url || "");
        setPlaybackBarOpen(true);
      } else if (!currSession.queue.has(0)) setPlaybackBarOpen(false);
    });

    currSession.on("session_kicked", () => {
      setSpecialErrorText("You've been kicked from the session!");
      setPlaying(false);
    });

    currSession.on("session_banned", () => {
      setSpecialErrorText("You've been banned from the session!");
      setPlaying(false);
    });
  }, [currSession]);

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: bigscreenViewOpen ? 0 : "100%",
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "#000000",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 3000,
          transition: "all 0.5s ease-in-out",
          zoom: 1,
          pb: bigscreenViewOpen && bigScreenVideoMode ? "0px" : "75px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0",
            right: "0",
            width: "100vw",
            height: "100vh",

            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            zIndex: 3000,
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "20vh",
              px: "5vw",
              py: "50px",
              flexGrowth: 0,

              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                width: "fit-content",

                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",

                gap: "10px",

                cursor: "pointer",
                userSelect: "none",

                backgroundColor: "#242424",
                borderRadius: "5px",
                px: "20px",
                py: "10px",

                "&:hover": {
                  backgroundColor: "#525252",
                },
                transition: "0.3s",

                opacity: bigScreenVideoMode ? 0 : 1,
                pointerEvents: bigScreenVideoMode ? "none" : "initial",
              }}
              onClick={() => setBigscreenViewOpen(false)}
            >
              <ArrowBackIosNew
                sx={{
                  transform: "rotate(270deg)",
                }}
              />
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                Minimize
              </Typography>
            </Box>

            <Box
              sx={{
                width: "fit-content",

                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",

                gap: "10px",

                cursor: "pointer",
                userSelect: "none",

                backgroundColor: "#242424",
                borderRadius: "5px",
                px: "20px",
                py: "10px",

                "&:hover": {
                  backgroundColor: "#525252",
                },
                transition: "0.3s",

                opacity: bigScreenVideoMode ? 0 : 1,
                pointerEvents: bigScreenVideoMode ? "none" : "initial",
              }}
              onClick={() => {
                if (document.fullscreenElement) {
                  document.exitFullscreen();
                } else {
                  document.documentElement.requestFullscreen();
                }
              }}
            >
              <OpenInFull
                sx={{
                  transform: "rotate(270deg)",
                }}
              />
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                Fullscreen
              </Typography>
            </Box>

            <Box
              sx={{
                ml: "auto",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                opacity: bigScreenVideoMode ? 0.5 : 1,
                transition: "opacity 1s ease-in-out",
              }}
            >
              <Switch
                checked={bigScreenVideoMode}
                onChange={() => setBigScreenVideoMode(!bigScreenVideoMode)}
              />
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#fff",
                  textAlign: "center",
                  userSelect: "none",
                }}
              >
                Video Mode
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "100%",

              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",

              opacity: bigScreenVideoMode ? 0 : 1,
              transition: "opacity 0.3s ease-in-out",
              pointerEvents: bigScreenVideoMode ? "none" : "initial",
            }}
          >
            <Box
              sx={{
                width: "50%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Avatar
                src={currSession?.queue.current()?.thumbnail}
                variant="square"
                sx={{ height: "25vw", width: "25vw" }}
              />

              <Typography
                sx={{
                  fontSize: "1.5vw",

                  width: "30vw",
                  maxWidth: "30vw",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",

                  fontWeight: "900",
                  color: "#fff",
                  marginTop: "20px",
                  textAlign: "center",
                }}
              >
                {currSession?.queue.current()?.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.75vw",
                  color: "#fff",
                  marginTop: "2px",
                  textAlign: "center",

                  width: "35vw",
                  maxWidth: "35vw",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
                onClick={() => {
                  setBigscreenViewOpen(false);
                }}
              >
                <Link
                  to={
                    currSession?.queue.current()?.albumID === "youtube"
                      ? `/youtube/${currSession?.queue.current()?.trackID}`
                      : `/artist/${currSession?.queue.current()?.artistID}`
                  }
                >
                  {currSession?.queue.current()?.artist}
                </Link>

                <span
                  style={{
                    color: "#fff",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  {" - "}
                </span>

                <Link
                  to={
                    currSession?.queue.current()?.albumID === "youtube"
                      ? `/youtube/${currSession?.queue.current()?.trackID}`
                      : `/album/${currSession?.queue.current()?.albumID}`
                  }
                >
                  {currSession?.queue.current()?.album}
                </Link>
              </Typography>
            </Box>
            <Box
              sx={{
                width: "50%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "10px",
                }}
              >
                <PageChip
                  title="Play Queue"
                  active={bigScreenPage === 0}
                  onClick={() => setBigScreenPage(0)}
                />
                <PageChip
                  title="Info"
                  active={bigScreenPage === 1}
                  onClick={() => setBigScreenPage(1)}
                />
                <PageChip
                  title="Session"
                  active={bigScreenPage === 2}
                  onClick={() => setBigScreenPage(2)}
                />
              </Box>

              {bigScreenPage === 0 && currSession?.queue.current() && (
                <Box
                  sx={{
                    mt: "20px",
                    height: "60vh",
                    maxHeight: "60vh",
                    overflowY: "auto",
                    width: "80%",

                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#CCC",
                      fontSize: "14px",
                      mb: "10px",
                      userSelect: "none",
                    }}
                  >
                    Currently Playing
                  </Typography>
                  <PlayQueueItem
                    track={currSession?.queue.current() as Types.QueueItem}
                  />

                  <Typography
                    sx={{
                      color: "#CCC",
                      fontSize: "14px",
                      mb: "10px",
                      mt: "20px",
                      userSelect: "none",
                      display:
                        currSession?.queue.getItems().length > 0
                          ? "initial"
                          : "none",
                    }}
                  >
                    Up Next
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      gap: "10px",
                    }}
                  >
                    {currSession?.queue.getItems().map((track, i) => (
                      <PlayQueueItem
                        key={i}
                        track={track}
                        onStartPlay={() => {
                          if (!currSession?.SessionID) return;
                          currSession.playNow(track.trackID);
                        }}
                        onRemove={() => {
                          if (!currSession?.SessionID) return;
                          currSession.removeQueueItem(i + 1);
                        }}
                      />
                    ))}
                  </Box>
                </Box>
              )}

              {bigScreenPage === 1 && currSession?.queue.current() && (
                <InfoScreen currSession={currSession} />
              )}

              {bigScreenPage === 2 && currSession?.queue.current() && (
                <SessionInfoScreen currSession={currSession} />
              )}
            </Box>
          </Box>
        </Box>
        <ReactPlayer
          ref={player}
          url={url}
          playing={playing}
          volume={volume}
          pip={false}
          onProgress={(progress) => {
            setProgress(progress.playedSeconds);
            setBufferProgress(progress.loaded * 100);
            setFavorite(
              favManager.has(currSession?.queue.current()?.trackID || "")
            );
          }}
          controls={false}
          config={{
            youtube: {
              playerVars: {
                controls: 0,
                modestbranding: 1,
                disablekb: 1,
                rel: 0,
                showinfo: 0,
                fs: 0,
                iv_load_policy: 3,
                loop: 0,
                playsinline: 1,
                origin: window.location.origin,
                autoplay: 1,
              },
            },
          }}
          style={{
            width: "120vw",
            height: "100vh",
            userSelect: "none",
            pointerEvents: "none",
            filter: bigScreenVideoMode
              ? "none"
              : "blur(10px) brightness(0.5) drop-shadow(0px 0px 10px #000)",
          }}
          width="120vw"
          height="120vh"
          progressInterval={100}
          // onPause={() => {
          //   if (!currSession?.SessionID) return;
          //   if (currSession.playback.paused) return;
          //   currSession.pause();
          // }}
          // onPlay={() => {
          //   if (!currSession?.SessionID) return;
          //   if (!currSession.playback.paused) return;
          //   currSession.resume();
          // }}
          onError={(err) => {
            console.error(err);
            if (typeof err === "number") {
              toastr.error(
                youtubeErrorConverter(
                  err,
                  `${currSession?.queue.current()?.artist} - ${
                    currSession?.queue.current()?.title
                  }`
                ).message,
                "Playback error",
                {
                  timeOut: 10000,
                  extendedTimeOut: 5000,
                  progressBar: true,
                }
              );
              currSession?.next();
            } else {
              toastr.error("An unexpected error occurred", "Playback error", {
                timeOut: 10000,
                extendedTimeOut: 5000,
                progressBar: true,
              });
              currSession?.next();
            }
          }}
        />
      </Box>
      <Backdrop
        open={Boolean(specialErrorText)}
        sx={{
          zIndex: 3000,
        }}
      >
        <Box
          style={{
            backgroundColor: "#202020",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
            gap: "10px",
          }}
        >
          <Typography
            style={{
              color: "white",
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            {specialErrorText}
          </Typography>

          <Button
            variant="contained"
            onClick={() => {
              if (currSession?.SessionID)
                document.location.href = `/join/${currSession?.SessionID}`;
              else document.location.reload();
            }}
          >
            {currSession?.SessionID ? "Rejoin" : "Reload"}
          </Button>
        </Box>
      </Backdrop>
      <Box
        sx={{
          backgroundColor:
            bigScreenVideoMode && bigscreenViewOpen ? "transparent" : "#000000",
          position: "fixed",
          bottom: playbackBarOpen ? 0 : -80,
          left: 0,
          width: "100%",
          height: "75px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          padding: "0 10px",
          zIndex: 3000,
          transition:
            "background-color 0.5s ease-in-out, bottom 0.5s ease-in-out",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
        }}
      >
        <span
          style={{
            position: "absolute",
            left: 0,
            width: "100%",
            height: "3px",
            zIndex: 100,
            alignSelf: "flex-start",
            marginTop: "-15px",
          }}
        >
          <Slider
            style={{
              width: "100%",
              height: "3px",
              zIndex: 200,
            }}
            min={0}
            max={currSession?.queue.current()?.duration || 0}
            value={progress}
            size="small"
            onChange={(e, v) => {
              setProgress(v as number);
            }}
            onChangeCommitted={(e, v) => {
              if (!currSession?.SessionID) return;
              player.current?.seekTo(v as number);
              setProgress(v as number);
              currSession.seek(v as number);
            }}
          />
          <LinearProgress
            sx={{
              width: "100%",
              height: "3px",
              marginTop: "-23px",
              position: "absolute",
              zIndex: 100,
              opacity: 0.4,
            }}
            color="secondary"
            variant="determinate"
            value={bufferProgress}
          />
        </span>

        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "33%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              width: "65px",
              height: "65px",
              minWidth: "65px",
              minHeight: "65px",
              marginTop: "4px",
              background: `url(${
                currSession?.queue.current()?.thumbnail
              }) center/cover`,
              borderRadius: "5px",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
              "& > *": {
                opacity: 0,
              },
              "&:hover": {
                background: `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.6)), url(${
                  currSession?.queue.current()?.thumbnail
                }) center/cover`,
                "& > *": {
                  opacity: 1,
                },
              },
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              if (!currSession?.SessionID) return;
              setBigscreenViewOpen(!bigscreenViewOpen);
            }}
          >
            <ArrowBackIosNew
              sx={{
                color: "#fff",
                fontSize: "20px",
                fontWeight: 600,
                transform: bigscreenViewOpen
                  ? "rotate(270deg)"
                  : "rotate(90deg)",
                transition: "transform 0.3s ease-in-out",
                position: "absolute",
              }}
            />
          </Box>

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              textAlign: "left",
              gap: "3px",
            }}
            onClick={() => {
              if (!currSession?.SessionID) return;
              setBigscreenViewOpen(false);
            }}
          >
            <Typography
              style={{
                color: "#fff",
                fontSize: "14px",
                fontWeight: 600,
                lineHeight: "16px",
                letterSpacing: "0.015em",
                marginLeft: "10px",
              }}
            >
              {currSession?.queue.current()?.title}
            </Typography>
            <Typography
              style={{
                color: "#fff",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "14px",
                letterSpacing: "0.015em",
                marginLeft: "10px",
              }}
            >
              <Link
                to={
                  currSession?.queue.current()?.albumID === "youtube"
                    ? `/youtube/${currSession?.queue.current()?.trackID}`
                    : `/artist/${currSession?.queue.current()?.artistID}`
                }
              >
                {currSession?.queue.current()?.artist}
              </Link>

              <span
                style={{ color: "#fff", marginLeft: "5px", marginRight: "5px" }}
              >
                {" "}
                -{" "}
              </span>

              <Link
                to={
                  currSession?.queue.current()?.albumID === "youtube"
                    ? `/youtube/${currSession?.queue.current()?.trackID}`
                    : `/album/${currSession?.queue.current()?.albumID}`
                }
              >
                {currSession?.queue.current()?.album}
              </Link>
            </Typography>

            <Typography
              style={{
                color: "#555",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "14px",
                letterSpacing: "0.015em",
                marginLeft: "10px",
              }}
            >
              {playbackBarOpen
                ? `${formatTime(Math.floor(progress))} / ${formatTime(
                    Math.floor(currSession?.queue.current()?.duration || 0)
                  )}`
                : ""}
            </Typography>
          </Box>
        </Box>

        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: "33%",
            height: "100%",
          }}
        >
          <Tooltip title="Go to track start">
            <IconButton
              sx={{ fontSize: 20 }}
              style={{
                color: "#fff",
                marginRight: "10px",
                fontSize: "20px",
              }}
              onClick={() => {
                if (!currSession?.SessionID) return;
                currSession.prev();
              }}
            >
              <SkipPrevious fontSize="small" />{" "}
            </IconButton>
          </Tooltip>

          <Tooltip title="Play/Pause">
            <IconButton
              sx={{ fontSize: 20 }}
              style={{
                color: "#fff",
                marginRight: "10px",
                fontSize: "20px",
              }}
              onClick={() => {
                togglePlaying();
                if (playing) {
                  currSession?.pause();
                } else {
                  currSession?.resume();
                }
              }}
            >
              {playing ? <Pause /> : <PlayArrow />}
            </IconButton>
          </Tooltip>

          <Tooltip title="Stop">
            <IconButton
              sx={{ fontSize: 20 }}
              style={{
                color: "#fff",
                marginRight: "10px",
                fontSize: "20px",
              }}
              onClick={() => {
                if (!currSession?.SessionID) return;
                currSession.stop();
              }}
            >
              <Stop fontSize="small" />
            </IconButton>
          </Tooltip>

          <Tooltip title="Skip">
            <IconButton
              sx={{ fontSize: 20 }}
              style={{
                color: "#fff",
                marginRight: "10px",
                fontSize: "20px",
              }}
              onClick={() => {
                if (!currSession?.SessionID) return;
                currSession.next();
              }}
              disabled={!currSession?.queue.has(1)}
            >
              <SkipNext
                fontSize="small"
                style={{
                  color: currSession?.queue.has(1) ? "#fff" : "#555",
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "33%",
            height: "100%",
          }}
        >
          {FavButton(favorite, currSession, setFavorite)}

          <Tooltip title={autoplay ? "Turn off Autoplay" : "Turn on Autoplay"}>
            <IconButton
              sx={{
                color: "#fff",
                marginRight: "10px",
                fontSize: "20px",
              }}
              onClick={() => {
                toggleAutoplay();
                currSession?.setPlaybackOptions({
                  autoplay: !autoplay,
                });
              }}
            >
              {autoplay ? <ShuffleOn /> : <Shuffle />}
            </IconButton>
          </Tooltip>

          <QueueButton queue={currSession?.queue} currSession={currSession} />

          <Slider
            style={{
              height: "60%",
            }}
            sx={{
              '& input[type="range"]': {
                WebkitAppearance: "slider-vertical",
              },
            }}
            orientation="vertical"
            size="small"
            min={0}
            max={100}
            value={Math.round(volume * 100)}
            onChange={(e, v) => {
              setVolume((v as number) / 100);
              localStorage.setItem("volume", (v as number).toString());
            }}
            aria-label="Volume"
            valueLabelDisplay="auto"
          />
        </Box>
      </Box>
    </>
  );
}

export default PlayBackBar;

function FavButton(
  favorite: boolean,
  currSession: SessionManager | undefined,
  setFavorite: React.Dispatch<React.SetStateAction<boolean>>
) {
  const [praiseOpen, setPraiseOpen] = useState<boolean>(false);
  const [praising, setPraising] = useState<string>("");
  const anchor = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const listener = (data: { initiator: Types.Member; track: string }) => {
      if (!currSession?.queue.current()) return;
      setPraising(data.initiator.username);
      setPraiseOpen(true);

      setTimeout(() => {
        setPraiseOpen(false);
      }, 5000);
    };
    currSession?.on("usr_fav_praise", listener);
    return () => {
      currSession?.off("usr_fav_praise", listener);
    };
  }, [currSession]);

  return (
    <>
      <Popover
        id="praisePopper"
        open={praiseOpen}
        anchorEl={anchor.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transitionDuration={500}
        sx={{
          zIndex: 5000,
          pointerEvents: "none",
        }}
      >
        <Box
          sx={{
            padding: "10px",
            backgroundColor: "#121212",
            color: "#fff",
            borderRadius: "5px",
          }}
        >
          <Typography>
            <strong
              style={{
                color: "#f55",
                textTransform: "capitalize",
              }}
            >
              {praising}
            </strong>{" "}
            has added this track to their favorites!
          </Typography>
        </Box>
      </Popover>
      <Tooltip
        title={favorite ? "Remove from Favorites" : "Add to Favorites"}
        ref={anchor}
      >
        <IconButton
          aria-describedby="praisePopper"
          sx={{
            color: praiseOpen ? "#f55" : "#fff",
            transition: "all 0.5s ease",
            marginRight: "10px",
            fontSize: "20px",
            transform: praiseOpen ? "scale(1.3)" : "scale(1)",
          }}
          onClick={() => {
            if (!currSession?.queue.current()) return;
            const track = currSession?.queue.current();
            if (!track) return;

            if (favorite) {
              favManager.remove(track.trackID);
              setFavorite(false);
            } else {
              favManager.add(track.trackID, {
                name: track.title,
                type: "track",
                artist: track.artist,
                album: track.album,
                id: track.trackID,
                albumID: track.albumID,
                artistID: track.artistID,
                thumbnail: track.thumbnail,
              });
              setFavorite(true);
            }
          }}
        >
          {favorite ? <Favorite /> : <FavoriteBorder />}
        </IconButton>
      </Tooltip>
    </>
  );
}

function PlayQueueItem({
  track,
  onStartPlay,
  onRemove,
}: {
  track: Types.QueueItem;
  onStartPlay?: () => void;
  onRemove?: () => void;
}) {
  const { setBigscreenViewOpen } = useUI();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",

        "&:hover": {
          backgroundColor: "#12121255",
          transition: "0s",
        },
        transition: "0.2s",
      }}
      onClick={() => {
        onStartPlay?.();
      }}
    >
      <Avatar
        src={track.thumbnail}
        variant="square"
        sx={{ height: "50px", width: "50px" }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          marginLeft: "10px",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setBigscreenViewOpen(false);
        }}
      >
        <Link
          to={
            track.albumID === "youtube"
              ? `/youtube/${track.trackID}`
              : `/album/${track.albumID}`
          }
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            {track.title}
          </Typography>
        </Link>
        <Link
          to={
            track.artistID === "youtube"
              ? `/youtube/${track.trackID}`
              : `/artist/${track.artistID}`
          }
        >
          <Typography
            sx={{
              fontSize: "14px",
            }}
          >
            by {track.artist}
          </Typography>
        </Link>
      </Box>

      <IconButton
        sx={{
          ml: "auto",
          display: onRemove ? "initial" : "none",
        }}
        onClick={(e) => {
          e.stopPropagation();
          onRemove?.();
        }}
      >
        <Close />
      </IconButton>
    </Box>
  );
}

function InfoScreen({ currSession }: { currSession: SessionManager }) {
  if (!currSession || !currSession.queue.current()) return <></>;

  const { delay } = usePlaybackDebugStats();

  return (
    <Box
      sx={{
        mt: "20px",
        height: "60vh",
        maxHeight: "60vh",
        overflowY: "auto",
        width: "80%",

        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          userSelect: "none",
        }}
      >
        <Typography
          sx={{
            color: "#CCC",
            fontSize: "14px",
          }}
        >
          Playing from
        </Typography>
        <Link
          to={(currSession.queue.current() as Types.QueueItem).url}
          target="_blank"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "5px",
          }}
        >
          <YoutubeIcon />
          <Typography
            sx={{
              color: "#fff",
            }}
          >
            YouTube
          </Typography>
        </Link>
      </Box>

      <Divider sx={{ width: "100%", my: "10px" }} />

      {(currSession.queue.current() as Types.QueueItem).albumID !==
        "youtube" && (
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              userSelect: "none",
            }}
          >
            <Typography
              sx={{
                color: "#CCC",
                fontSize: "14px",
              }}
            >
              Metadata from
            </Typography>
            <Link
              to={`https://open.spotify.com/track/${
                (currSession.queue.current() as Types.QueueItem).trackID
              }`}
              target="_blank"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "7px",
              }}
            >
              <SpotifyIcon color="#fff" />
              <Typography
                sx={{
                  color: "#fff",
                }}
              >
                Spotify
              </Typography>
            </Link>
          </Box>
          <Divider sx={{ width: "100%", my: "10px" }} />
        </>
      )}

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          userSelect: "none",
        }}
      >
        <Typography
          sx={{
            color: "#CCC",
            fontSize: "14px",
          }}
        >
          Server to client delay
        </Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <Timelapse />
          <Typography
            sx={{
              color: "#fff",
            }}
          >
            {Math.round(delay)} ms
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
}

function SessionInfoScreen({ currSession }: { currSession: SessionManager }) {
  if (!currSession || !currSession.queue.current()) return <></>;

  const { delay } = usePlaybackDebugStats();

  return (
    <Box
      sx={{
        mt: "20px",
        height: "60vh",
        maxHeight: "60vh",
        overflowY: "auto",
        width: "80%",

        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        gap: "5px",
      }}
    >
      <Typography
        sx={{
          color: "#CCC",
          fontSize: "14px",
          mb: "10px",
          userSelect: "none",
        }}
      >
        Session ({currSession.users.length} / 8 users)
      </Typography>
      {currSession.users.map((user, i) => (
        <Box
          key={i}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "10px",
            userSelect: "none",
            backgroundColor: "#12121255",
          }}
        >
          <Avatar
            src={user.avatarURL}
            variant="rounded"
            sx={{ height: "50px", width: "50px" }}
          />
          <Typography
            sx={{
              color: "#fff",
              fontSize: "20px",
              fontWeight: "bold",
              mb: "2px",
              textTransform: "capitalize",
            }}
          >
            {user.username}
          </Typography>

          <Box
            sx={{
              ml: "auto",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "5px",
            }}
          >
            {user.premium && (
              <Tooltip arrow placement="top" title="Premium user">
                <Star sx={{
                  color: "#ffd700",
                  filter: "drop-shadow(0px 0px 5px #ffd700)",
                }} />
              </Tooltip>
            )}

            {i === 0 && (
              <Tooltip arrow placement="top" title="Host">
                <MusicNote />
              </Tooltip>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
}
