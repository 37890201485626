
export default async function ImageToGradient(imageURL: string) {
    try {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if(!ctx) throw new Error('Could not get canvas context');
        const img = new Image();
        img.crossOrigin = "*";
        img.src = imageURL;
        await new Promise<void>((resolve, reject) => {
            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);
                resolve();
            }
            img.onerror = (err) => {
                reject(err);
            }
        });
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
        const colors = [];
        for(let i = 0; i < data.length; i += 4) {
            const r = data[i];
            const g = data[i + 1];
            const b = data[i + 2];
            const a = data[i + 3];
            if(a > 0) {
                colors.push({ r, g, b, a });
            }
        }
        const color = averageColor(colors);
        return color;
    } catch(err) {
        console.error(err);
        return { r: 0, g: 0, b: 0, a: 1 };
    }
}

function averageColor(colors: { r: number, g: number, b: number, a: number }[]) {
    let r = 0;
    let g = 0;
    let b = 0;
    let a = 0;
    for(const color of colors) {
        r += color.r;
        g += color.g;
        b += color.b;
        a += color.a;
    }
    r /= colors.length;
    g /= colors.length;
    b /= colors.length;
    a /= colors.length;
    return { r, g, b, a };
}