import { PlayArrow } from "@mui/icons-material";
import { Box, Avatar, IconButton } from "@mui/material";
import { useState } from "react";

function ClickableTrackIcon({
  track,
  onClick,
}: {
  track: SpotifyApi.TrackObjectFull;
  onClick: () => void;
}) {
  const [hovered, setHovered] = useState<boolean>(false);

  return (
    <Box
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        px: "10px",
      }}
    >
      <Avatar
        variant="square"
        src={track.album.images[0]?.url}
        sx={{
          width: "60px",
          height: "60px",
          transition: "all 0.2s ease-in-out",
          filter: hovered ? "brightness(0.5)" : "brightness(1)",
        }}
      />
      <IconButton
        sx={{
          position: "absolute",
          width: "50px",
          height: "50px",

          marginLeft: "5px",
          marginTop: "-53px",

          opacity: hovered ? 1 : 0,
          transition: "all 0.2s ease-in-out",
        }}
        onClick={onClick}
      >
        <PlayArrow
          sx={{
            width: "30px",
            height: "30px",
          }}
        />
      </IconButton>
    </Box>
  );
}

export default ClickableTrackIcon;
