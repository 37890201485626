import { create } from 'zustand'

type PlaybackState = {
    playing: boolean
    autoplay: boolean
    togglePlaying: () => void   
    setPlaying: (playing: boolean) => void
    toggleAutoplay: () => void
    setAutoplay: (autoplay: boolean) => void
}

const usePlayback = create<PlaybackState>((set) => ({
    playing: false,
    autoplay: false,
    togglePlaying: () => set((state: PlaybackState) => ({ playing: !state.playing })),
    setPlaying: (playing: boolean) => set({ playing }),
    toggleAutoplay: () => set((state: PlaybackState) => ({ autoplay: !state.autoplay })),
    setAutoplay: (autoplay: boolean) => set({ autoplay })
}))
export default usePlayback;