import { Backdrop, Box, CircularProgress, Stack } from '@mui/material'
import { useEffect } from 'react'

function Login() {

  useEffect(() => {
    setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_BACKEND_URL}/auth/web/discord`
    }, 2500)
  })

  return (
    <Box>
      <Backdrop 
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
    >
        <Stack direction='column' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <h1>Redirecting to discord</h1>
            <CircularProgress />
        </Stack>
    </Backdrop>
    </Box>
  )
}

export default Login