import { ContentCopy } from "@mui/icons-material";
import SessionManager from "../sessionManager";
import {
  Backdrop,
  Box,
  Button,
  Divider,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { success } from "toastr";

const dividerBoxStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  height: "100px",
  width: "100%",
} as React.CSSProperties;

function SharePopup({
  session,
  openHook,
}: {
  session: SessionManager | undefined;
  openHook: [boolean, (open: boolean) => void];
}) {
  const [open, setOpen] = openHook;

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={(e) => {
        // check whether the click was outside the popup
        if (e.target === e.currentTarget) setOpen(false);
      }}
    >
      <Box
        style={{
          backgroundColor: "#202020",
          padding: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
        }}
      >
        <Typography
          style={{
            color: "white",
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          Lets get this party started! 🎶
        </Typography>
        <Typography
          style={{
            color: "white",
            fontSize: "1rem",
            fontStyle: "italic",
          }}
        >
          Share the link or the session ID with your friends to get them to
          join!
        </Typography>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Box style={dividerBoxStyle}>
            <TextField
              placeholder="SessionID"
              value={session?.SessionID}
              sx={{ textAlign: "center", width: "100%" }}
            />
            <Tooltip title="Copy">
              <Button
                style={{
                  fontWeight: "bold",
                  fontSize: "1.25rem",
                  height: "70%",
                  marginLeft: "10px",
                }}
                onClick={() => {
                  navigator.clipboard.writeText(session?.SessionID ?? "");
                }}
              >
                <ContentCopy />
              </Button>
            </Tooltip>
          </Box>
          <Divider
            style={{
              width: "80%",
            }}
          >
            <Typography
              style={{
                color: "white",
                fontSize: "0.75rem",
                fontStyle: "italic",
              }}
            >
              OR
            </Typography>
          </Divider>
          <Box style={dividerBoxStyle}>
            <TextField
              placeholder="Session Link"
              value={`${window.location.origin}/join/${session?.SessionID}`}
              sx={{ textAlign: "center", width: "100%" }}
            />
            <Tooltip title="Copy">
              <Button
                style={{
                  fontWeight: "bold",
                  fontSize: "1.25rem",
                  height: "70%",
                  marginLeft: "10px",
                }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${window.location.origin}/join/${session?.SessionID}` ?? ""
                  );
                }}
              >
                <ContentCopy />
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Backdrop>
  );
}

export default SharePopup;
