import {
  Backdrop,
  Box,
  Button,
  Typography,
  CircularProgress,
  Collapse,
  Alert,
} from "@mui/material";
import { socket } from "../App";
import SessionManager from "../sessionManager";
import { Types } from "../types";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";

function AudioPlayer({
  currSessionProp,
}: {
  currSessionProp: [
    SessionManager | undefined,
    (session: SessionManager | undefined) => void
  ];
}) {
  let { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  let [currSession, setCurrSession] = currSessionProp;
  const [sessionLoading, setSessionLoading] = useState<boolean>(false);

  const [error, setError] = useState<string | null>(null);

  return (
    <Backdrop open={true}>
      <Box
        sx={{
          backgroundColor: "#202020",
          padding: "30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px",
        }}
      >
        <Collapse in={error !== null}>
          {" "}
          <Alert severity="error">{error}</Alert>{" "}
        </Collapse>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Typography
            style={{
              color: "white",
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            You've been invited to join a session!
          </Typography>
        </Box>

        <Button
          variant="contained"
          color="primary"
          sx={{
            marginTop: "20px",
            fontSize: "1.2rem",
            fontWeight: "bold",
            // change the disabled color
            "&.Mui-disabled": {
              color: "white",
              backgroundColor: "#808a89",
            },
          }}
          disabled={sessionLoading}
          onClick={() => {
            if (currSession?.SessionID) return;
            setSessionLoading(true);

            socket.emit(
              "joinSession",
              id,
              (res: Types.SessionJoinResponse | Types.SocketError) => {
                if (res.error) return console.log(res.error), setError(res.message), setSessionLoading(false);

                console.log(res);
                setCurrSession(
                  new SessionManager({
                    sessionID: res.sessionID,
                    socket: socket,
                    settings: res.settings,
                    playback: res.playback,
                    queue: res.queue,
                    users: res.users,
                  })
                );
                navigate(`/`);
              }
            );
          }}
        >
          {sessionLoading ? <CircularProgress size={36} /> : "Join"}
        </Button>
      </Box>
    </Backdrop>
  );
}

export default AudioPlayer;
