import { Link, useParams } from "react-router-dom";
import SessionManager from "../sessionManager";
import { useEffect, useState } from "react";
import { Spotify } from "../spotify";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  PlayArrow,
  FavoriteBorder,
  PlaylistAdd,
  MoreHoriz,
  Favorite,
  AudiotrackRounded,
} from "@mui/icons-material";
import { favManager } from "../App";
import * as toastr from "toastr";

// components
import SpotifyIcon from "../components/SpotifyIcon";
import ArtistNames from "../components/ArtistNames";
import AlbumTrackRow from "../components/AlbumTrackRow";

function Album({ currSession }: { currSession: SessionManager | undefined }) {
  const { id } = useParams<{ id: string }>();

  const [albumData, setAlbumData] =
    useState<SpotifyApi.SingleAlbumResponse | null>(null);
  const [albumTracks, setAlbumTracks] =
    useState<SpotifyApi.AlbumTracksResponse | null>(null);

  const [favourite, setFavourite] = useState<boolean>(false);

  useEffect(() => {
    if (id === undefined) return;

    setFavourite(favManager.has(id));

    setAlbumData(null);
    setAlbumTracks(null);

    Spotify.getAlbum(id).then(async (res) => {
      console.log(res);
      setAlbumData(res);

      const tracks = await Spotify.getAlbumTracks(id);
      console.log(tracks);
      setAlbumTracks(tracks);
    });
  }, [id]);

  const [albumOptionsMenuAnchor, setAlbumOptionsMenuAnchor] =
    useState<null | HTMLElement>(null);
  const albumOptionsMenuOpen = Boolean(albumOptionsMenuAnchor);

  if (albumData === null)
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        pr: "40px",
        gap: "40px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <Typography
            sx={{
              fontSize: "40px",
              fontWeight: "bold",

              // one line max no wrap
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {albumData?.name}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              mt: "15px",
              gap: "10px",
            }}
          >
            <Link to={`/artist/${albumData?.artists[0].id}`}>
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#FFFFFF",
                  fontFamily: '"Montserrat Variable", sans-serif',
                  fontWeight: "600",
                  // one line max no wrap
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {albumData?.artists[0].name}
              </Typography>
            </Link>

            <Typography
              sx={{
                fontSize: "14px",
                color: "#FFFFFFAA",
                fontFamily: '"Montserrat Variable", sans-serif',
              }}
            >
              •
            </Typography>

            <Typography
              sx={{
                fontSize: "14px",
                color: "#FFFFFFAA",
                fontFamily: '"Montserrat Variable", sans-serif',
              }}
            >
              {albumData?.release_date.split("-")[0]}
            </Typography>

            <Typography
              sx={{
                fontSize: "14px",
                color: "#FFFFFFAA",
                fontFamily: '"Montserrat Variable", sans-serif',
              }}
            >
              •
            </Typography>

            <Typography
              sx={{
                fontSize: "14px",
                color: "#FFFFFFAA",
                fontFamily: '"Montserrat Variable", sans-serif',
              }}
            >
              {albumData?.total_tracks} Tracks
            </Typography>

            <Typography
              sx={{
                fontSize: "14px",
                color: "#FFFFFFAA",
                fontFamily: '"Montserrat Variable", sans-serif',
              }}
            >
              •
            </Typography>

            <Typography
              sx={{
                fontSize: "14px",
                color: "#FFFFFFAA",
                fontFamily: '"Montserrat Variable", sans-serif',
              }}
            >
              {Math.floor(
                albumData.tracks.items.reduce(
                  (acc, curr) => acc + curr.duration_ms,
                  0
                ) / 60000
              )}{" "}
              Minutes
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "20px",
            marginBottom: "20px",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "30px",
          }}
        >
          <Box
            sx={{
              width: "50px",
              color: "black",
              aspectRatio: "1/1",
              borderRadius: "50%",
              background: (theme) => theme.palette.primary.main,
              boxShadow: "0px 2px 5px 2px #00000055",
              cursor: "pointer",

              "&:hover": {
                backgroundColor: (theme) => theme.palette.primary.dark,
              },

              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              if (!currSession) return;
              let trackIds: string[] = [];
              albumTracks?.items.forEach((t) => trackIds.push(t.id));
              if (!(trackIds.length > 0)) return;
              currSession.playNow(trackIds.slice(0, 25)).catch((err) => {
                toastr.error(err.message);
              });
            }}
          >
            <PlayArrow
              sx={{
                fontSize: "30px",
              }}
            />
          </Box>
          <Tooltip title="Open on Spotify">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",

                cursor: "pointer",
                width: "40px",
                height: "40px",
              }}
              onClick={() => window.open(albumData?.external_urls.spotify)}
            >
              <SpotifyIcon color="white" />
            </Box>
          </Tooltip>
          <Tooltip title={`${favourite ? "Remove from" : "Add to"} favourites`}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",

                cursor: "pointer",
                width: "40px",
                height: "40px",
              }}
              onClick={() => {
                if (!albumData) return;
                if (favourite) {
                  favManager.remove(albumData.id);
                  setFavourite(false);
                } else {
                  favManager.add(albumData.id, {
                    name: albumData.name,
                    type: "album",
                    artist: albumData.artists[0].name,
                    album: albumData.name,
                    id: albumData.id,
                    albumID: albumData.id,
                    artistID: albumData.artists[0].id,
                    thumbnail: albumData.images[0].url,
                  });
                  setFavourite(true);
                }
              }}
            >
              {favourite ? (
                <Favorite sx={{ color: "white" }} />
              ) : (
                <FavoriteBorder sx={{ color: "white" }} />
              )}
            </Box>
          </Tooltip>
          <Tooltip title="Add to Queue">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",

                cursor: "pointer",
                width: "40px",
                height: "40px",
              }}
              onClick={() => {
                if (
                  !currSession ||
                  !albumTracks ||
                  albumTracks.items.length === 0
                )
                  return;
                currSession
                  .addToQueue(albumTracks?.items.map((track) => track.id))
                  .catch((err) => {
                    toastr.error(err.message);
                  });
              }}
            >
              <PlaylistAdd sx={{ color: "white" }} />
            </Box>
          </Tooltip>
        </Box>

        <Table
          sx={{
            width: "100%",
            size: "small",
            border: "none",
            marginTop: "10px",
            borderTop: "1px solid gray",
            borderBottom: "1px solid gray",
            "& .MuiTableCell-root": {
              border: "none",
              fontSize: "16px",
              padding: "5px",
            },
          }}
        >
          <TableBody>
            {albumTracks === null && (
              <TableRow>
                <TableCell
                  sx={{
                    border: "none",
                    fontSize: "16px",
                    padding: "5px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  colSpan={4}
                >
                  <CircularProgress color="inherit" />
                </TableCell>
              </TableRow>
            )}
            {albumTracks?.items.length === 0 && (
              <TableRow>
                <TableCell
                  sx={{
                    border: "none",
                    fontSize: "16px",
                    padding: "5px",
                  }}
                  colSpan={4}
                >
                  No Tracks Found
                </TableCell>
              </TableRow>
            )}
            {albumTracks?.items.map((track, index) => (
              <AlbumTrackRow
                key={index}
                index={index + 1}
                trackName={track.name}
                trackId={track.id}
                aristElement={
                  <ArtistNames
                    names={track.artists.map((a) => a.name)}
                    links={track.artists.map((a) => `/artist/${a.id}`)}
                  />
                }
                spotifyLink={track.external_urls.spotify}
                currSession={currSession}
                duration={Math.floor(track.duration_ms / 1000)}
                onPlayNow={() =>
                  currSession?.playNow(track.id).catch((err) => {
                    toastr.error(err.message);
                  })
                }
                onPlayNext={() =>
                  currSession?.playNext(track.id).catch((err) => {
                    toastr.error(err.message);
                  })
                }
                onAddToQueue={() =>
                  currSession?.addToQueue(track.id).catch((err) => {
                    toastr.error(err.message);
                  })
                }
                albumName={albumData?.name || ""}
                albumId={albumData?.id || ""}
                artistName={albumData?.artists[0].name || ""}
                artistId={albumData?.artists[0].id || ""}
                thumbnail={albumData?.images[0].url || ""}
              />
            ))}
          </TableBody>
        </Table>
      </Box>

      <Box
        sx={{
          width: "15vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <Avatar
          src={albumData?.images[0]?.url}
          variant="rounded"
          sx={{ width: "15vw", height: "15vw", aspectRatio: "1/1" }}
        />

        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {albumData?.genres.map((genre, index) => (
            <Grid item key={index}>
              <Chip label={genre} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default Album;
