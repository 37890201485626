import { create } from 'zustand'

type UIState = {
    playbackBarOpen: boolean
    setPlaybackBarOpen: (playbackBarOpen: boolean) => void

    windowFullScreen: boolean
    setWindowFullScreen: (windowFullScreen: boolean) => void

    bigscreenViewOpen: boolean
    setBigscreenViewOpen: (bigscreenViewOpen: boolean) => void
}

const useUI = create<UIState>((set) => ({
    playbackBarOpen: false,
    setPlaybackBarOpen: (playbackBarOpen: boolean) => set({ playbackBarOpen }),
    
    windowFullScreen: false,
    setWindowFullScreen: (windowFullScreen: boolean) => {
        set({ windowFullScreen })
    },

    bigscreenViewOpen: false,
    setBigscreenViewOpen: (bigscreenViewOpen: boolean) => set({ bigscreenViewOpen }),
}))
export default useUI;


document.addEventListener('fullscreenchange', (event) => {
    if (document.fullscreenElement) {
        useUI.getState().setWindowFullScreen(true)
    } else {
        useUI.getState().setWindowFullScreen(false)
    }
})