import {
  TableRow,
  TableCell,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Typography,
} from "@mui/material";
import { formatTime } from "../types";
import { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Favorite, FavoriteBorder, MoreVert, PlayArrow } from "@mui/icons-material";
import SessionManager from "../sessionManager";
import { favManager } from "../App";
import VisulizerAnim from "./VisulizerAnim";

// states
import usePlayback from "../states/PlaybackState";

function AlbumTrackRow({
  index,
  trackName,
  trackId,
  aristElement,
  spotifyLink,
  duration,
  onPlayNow,
  onPlayNext,
  onAddToQueue,
  currSession,
  albumName,
  albumId,
  artistName,
  artistId,
  thumbnail,
}: {
  index: number;
  trackName: string;
  trackId: string;
  aristElement: ReactElement;
  spotifyLink?: string;
  duration: number;
  onPlayNow?: () => void;
  onPlayNext?: () => void;
  onAddToQueue?: () => void;
  currSession: SessionManager | undefined;
  albumName: string;
  albumId: string;
  artistName: string;
  artistId: string;
  thumbnail: string;
}) {
  const [hovered, setHovered] = useState(false);

  const { playing } = usePlayback();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [favourite, setFavourite] = useState<boolean>(favManager.has(trackId));

  // for force re-render
  const [currTime, setCurrTime] = useState<number>(Date.now());
  useEffect(() => {
    if (!currSession) return;
    const listener = () => {
      setCurrTime(Date.now());
    };
    currSession.on("playback_next", listener);
    currSession.on("playback_start", listener);
    currSession.on("queue_update", listener);
    return () => {
      currSession.off("playback_next", listener);
      currSession.off("playback_start", listener);
      currSession.off("queue_update", listener);
    };
  }, [currSession]);

  return (
    <>
      <TableRow
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        sx={{
          backgroundColor: hovered ? "#00000055" : "transparent",
          transition: "background-color 0.2s ease-in-out",
        }}
      >
        <TableCell 
          sx={{
            textAlign: "center",
            width: "35px",
            height: "35px",
          }}
          align="left"
        >
          {(() => {
            if(currSession?.queue.current()?.trackID === trackId) return <VisulizerAnim playing={playing} />;
            if(hovered) return (
              <IconButton
                onClick={onPlayNow}
                sx={{
                  color: "#FFFFFF",
                  padding: "1px",
                }}
              >
                <PlayArrow
                  sx={{
                    color: "#FFFFFF",
                    fontSize: "20px",
                  }}
                />
              </IconButton>
            );
            return <Typography sx={{
              color: "#FFFFFF",
              fontSize: "14px",
            }}>{index}</Typography>
          })()}
        </TableCell>
        <TableCell>{trackName}</TableCell>
        <TableCell>
          {aristElement}
        </TableCell>
        <TableCell align="right" sx={{
          width: "32px",
        }}>
            <IconButton
              sx={{
                padding: "0px",
              }}
              onClick={() => {
                setFavourite(!favourite); 
                if(favourite) favManager.remove(trackId);
                else favManager.add(trackId, {
                  name: trackName,
                  type: "track",
                  artist: artistName,
                  album: albumName,
                  id: trackId,
                  albumID: albumId,
                  artistID: artistId,
                  thumbnail: thumbnail,
                });
              }}
            >
              {favourite ? (
                <Favorite sx={{ fontSize: "20px" }} />
              ) : (
                <FavoriteBorder sx={{ fontSize: "20px" }} />
              )}
            </IconButton>
        </TableCell>
        <TableCell align="right" sx={{
          width: "64px",
        }}>
          {hovered ? (
            <IconButton
              sx={{
                color: "#FFFFFF",
                padding: "0px",
              }}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
            >
              <MoreVert
                sx={{
                  color: "#FFFFFF",
                  fontSize: "20px",
                }}
              />
            </IconButton>
          ) : (
            formatTime(duration)
          )}
        </TableCell>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={() => {
            setAnchorEl(null);
            setHovered(false);
          }}
          onClick={() => {
            setAnchorEl(null);
            setHovered(false);
          }}
        >
          <MenuItem onClick={() => onPlayNext?.()}>Play Next</MenuItem>
          <MenuItem onClick={() => onAddToQueue?.()}>Add to Queue</MenuItem>
          {spotifyLink && (
            <>
              <Divider />
              <MenuItem
                onClick={() => {
                  window.open(spotifyLink, "_blank");
                }}
              >
                Open on Spotify
              </MenuItem>
            </>
          )}
        </Menu>
      </TableRow>
    </>
  );
}

export default AlbumTrackRow;
