import { UUID } from "crypto";
import './types.d.ts';

/**
 * Represents a queue of songs.
 */
export class Queue {
    private queue: Types.QueueItem[] = [];

    /**
     * Adds a new item to the end of the queue.
     * @param data The item to add to the queue.
     */
    add(data: Types.QueueItem) {
        this.queue.push(data);
    }

    /**
     * Sets the entire queue to the provided array of queue items.
     * @param queue An array of queue items to set as the new queue.
     */
    setQueue(queue: Types.QueueItem[]) {
        this.queue = queue;
    }

    /**
     * Removes the first entry from the queue.
     */
    next() {
        this.queue.shift();
    }

    /**
     * Removes an item from the queue at the specified index.
     * @param index The index of the item to remove.
     */
    remove(index: number) {
        this.queue.splice(index, 1);
    }

    /**
     * Returns the entire queue.
     * @returns An array of URLs in the queue.
     */
    getQueue() {
        return this.queue;
    }

    /**
     * Clears the queue.
     */
    clear() {
        this.queue = [];
    }

    /**
     * Checks if the queue is empty.
     * @returns A boolean indicating if the queue is empty.
     */
    isEmpty() {
        return this.queue.length === 0;
    }

    /**
     * Returns whether the queue has an item at the specified index.
     */
    has(index: number) {
        return this.queue[index] != undefined;
    }

    /**
     * Returns the first item in the queue.
     * @returns The first item in the queue.
     */
    current(): Types.QueueItem | undefined {
        return this.queue[0];
    }

    /**
     * Maps each item in the queue to a new value using the provided callback function.
     * @param callback A function that accepts up to three arguments. The map method calls the callback function one time for each element in the queue.
     * @returns An array containing the results of calling the provided function on every element in the queue.
     */
    map<T>(callback: (value: Types.QueueItem, index: number, array: Types.QueueItem[]) => T): T[] {
        return this.queue.map(callback);
    }

    /**
     * Get an array of all items in the queue except for the current item.
     * @returns An array of all items in the queue except for the current item.
     */
    getItems(): Types.QueueItem[] {
        return this.queue.slice(1);
    }
}

/**
 * Calculates the distance between two numbers.
 * @param x The first number.
 * @param y The second number.
 * @returns The absolute difference between the two numbers.
 */
export function dist(x: number, y: number) {
    return Math.abs(x - y);
}


/**
 * Formats a time value (in seconds) into a string of the format "mm:ss".
 * @param time The time value (in seconds) to format.
 * @returns A string of the format "mm:ss".
 */
export function formatTime(time: number) {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
}


/**
 * Converts a YouTube error code to a custom error object.
 * @param id The YouTube error code.
 * @param name The name of the track that caused the error.
 * @returns A custom error object with a type and message.
 */
export function youtubeErrorConverter(id: number, name: string): Types.Error {
    switch (id) {
        default:
            return {
                type: "unknown",
                message: `An unknown error occurred while trying to play ${name}. The track was skipped.`,
            } satisfies Types.Error;
        case 150:
            return {
                type: "unavailable",
                message: `The requested track ${name} is unavailable. The track was skipped.`,
            } satisfies Types.Error;
        case 100:
            return {
                type: "not-found",
                message: `The requested track ${name} wasn't found. The track was skipped.`,
            } satisfies Types.Error;
        case 101:
            return {
                type: "not-embeddable",
                message: `The owner of the requested track ${name} has disabled playback on other websites. The track was skipped.`,
            } satisfies Types.Error;
    }
}

export declare namespace Types {
    export interface User {
        id: string;
        username: string;
        avatarURL: string;
        bannerURL: string | null;
        bannerColor: string | null;
        locale: string;
        authToken: string;
        premium: boolean;
    }

    export interface DiscordUser {
        user_id: string;
        discord_id: BigInt;
        user: User;
    }

    export interface APIError {
        code: number;
        type: string;
        message: string;
    }

    export interface SocketError {
        error: true;
        type: string;
        message: string;
    }

    export interface QueueItem {
        url: string,
        title: string,
        trackID: string,
        album: string,
        albumID: string,
        artist: string,
        artistID: string,
        thumbnail: string,
        duration: number,
        member: Types.Member
    }

    export interface QueueUpdateRequest {
        multiple: boolean;
        tracks: Types.QueueItem | number
        user: Types.Member
    }

    export interface Member {
        socket: string
        username: string,
        id: UUID,
        avatarURL: string,
        bannerURL?: string,
        bannerColor: string,
        locale: string,
        premium: boolean,
    }

    export interface SessionJoinResponse {
        error: false;
        sessionID: string,
        settings: SessionSettings,
        users: Member[],
        queue: QueueItem[],
        playback: PlaybackState,
    }

    export interface SessionSettings {
        hostOnly: boolean,
        allowSkip: boolean,
    }

    export interface PlaybackState {
        paused: boolean,
        time: number,
        autoplay: boolean,
    }

    export interface Error {
        type: string,
        message: string,
    }

    export type SearchTypes = 'track' | 'artist' | 'album' | 'playlist';

    export interface AuthedQuery {
        id: string,
        token: string,
    }

    export interface SearchQuery extends AuthedQuery {
        query: string;
        type: SearchTypes | SearchTypes[];
        offset?: number;
        limit?: number;
    }

    export interface CallbackSuccess {
        error: false,
    }

    export interface CallbackError {
        error: true,
        type: string,
        message: string,
    }

    export interface YoutubeInfo {
        id: string;
        title: string;
        author: string;
        thumbnail: string;
        duration: number;
    }
}