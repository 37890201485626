import { Box, IconButton, Avatar, Menu, MenuItem, Divider } from "@mui/material";
import React from "react";

function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <Avatar
          src={localStorage.getItem("avatarURL") || ""}
          alt="Icon"
          style={{}}
        />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
        style={{
          marginTop: "40px",
          zIndex: 2000,
        }}
        sx={{
            "& .MuiMenuItem-root": {
                fontSize: "1rem",
                px: "30px",
            },
        }}
      >
        <Box style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            textTransform: "capitalize",
            fontWeight: "bold",
        }}>{localStorage.getItem("username")}</Box>
        <Divider />
        <MenuItem onClick={handleClose} disabled={true}>Profile</MenuItem>
        <MenuItem onClick={() => {
            localStorage.removeItem("token");
            localStorage.removeItem("username");
            localStorage.removeItem("avatarURL");
            window.location.href = process.env.REACT_APP_LANDING_URL || "/";
        }}>Logout</MenuItem>
      </Menu>
    </Box>
  );
}

export default AccountMenu;
