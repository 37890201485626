import { Box, Typography } from "@mui/material";
import React from "react";

function PageChip({
  title,
  onClick,
  active,
}: {
  title: string;
  onClick?: () => void;
  active?: boolean;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        userSelect: "none",

        backgroundColor: "transparent",
        borderRadius: "5px",
        py: "5px",
        px: "20px",
        cursor: "pointer",
        transition: "0.2s",

        ...(active && {
          backgroundColor: "#ffffff",
        }),
        ...(!active && {
          "&:hover": {
            backgroundColor: "#242429",
            transition: "0s",
          },
        }),
      }}
      onClick={onClick}
    >
      <Typography
        sx={{
          fontSize: "1rem",
          fontWeight: "bold",
          color: active ? "#242429" : "#CCCCCC",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
}

export default PageChip;
