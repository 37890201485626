import { create } from 'zustand'

interface PlaybackDebugStatsState {
    delay: number
    setDelay: (delay: number) => void
}

const usePlaybackDebugStats = create<PlaybackDebugStatsState>((set) => ({
    delay: 0,
    setDelay: (delay: number) => set({ delay }),
}))

export default usePlaybackDebugStats;