import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Tab,
  Table,
  TableBody,
  Tabs,
  Typography,
} from "@mui/material";
import { favManager } from "../App";
import SessionManager from "../sessionManager";
import * as toastr from "toastr";

// components
import CoverIcon from "../components/CoverIcon";
import AlbumCard from "../components/AlbumCard";
import FavoriteTrackRow from "../components/FavoriteTrackRow";
import { useEffect, useState } from "react";

function Favourites({
  currSession,
}: {
  currSession: SessionManager | undefined;
}) {
  const [artists, setArtists] = useState<JSX.Element[] | null>(null);
  const [albums, setAlbums] = useState<JSX.Element[] | null>(null);
  const [tracks, setTracks] = useState<JSX.Element[] | null>(null);

  const [tab, setTab] = useState<number>(
    sessionStorage.getItem("favouritesTab")
      ? parseInt(sessionStorage.getItem("favouritesTab")!)
      : 0
  );

  useEffect(() => {
    sessionStorage.setItem("favouritesTab", tab.toString());
  }, [tab]);

  useEffect(() => {
    setArtists(
      favManager
        .getAll(false)
        .filter((i) => i.type === "artist")
        .map((i) => (
          <Grid item key={i.id}>
            <CoverIcon
              imageUrl={i.thumbnail}
              title={i.name}
              id={i.id}
              type="artist"
            />
          </Grid>
        ))
    );

    setAlbums(
      favManager
        .getAll(false)
        .filter((i) => i.type === "album")
        .map((i) => (
          <Grid item key={i.id}>
            <AlbumCard
              albumName={i.name}
              albumImage={i.thumbnail}
              albumLink={`/album/${i.id}`}
              albumExtra={`${i.artist}`}
              albumSpotifyLink={`https://open.spotify.com/album/${i.id}`}
              albumID={i.id}
              artistName={i.artist || ""}
              artistID={i.id || ""}
              currSession={currSession}
              onPlayNow={() =>
                currSession?.playAlbumNow(i.id).catch((err) => {
                  toastr.error(err.message);
                })
              }
              onPlayNext={() =>
                currSession?.playAlbumNext(i.id).catch((err) => {
                  toastr.error(err.message);
                })
              }
              onAddToQueue={() =>
                currSession?.addAlbumToQueue(i.id).catch((err) => {
                  toastr.error(err.message);
                })
              }
            />
          </Grid>
        ))
    );

    setTracks(
      favManager
        .getAll(false)
        .filter((i) => i.type === "track" || i.type === "youtube")
        .map((track, index) => (
          <FavoriteTrackRow
            key={index}
            index={index + 1}
            trackName={track.name}
            trackId={track.id}
            link={
              track.albumID === "youtube"
                ? `https://www.youtube.com/watch?v=${track.id}`
                : `https://open.spotify.com/track/${track.id}`
            }
            currSession={currSession}
            onPlayNow={() =>
              currSession
                ?.playNow(
                  track.albumID === "youtube" ? `youtube:${track.id}` : track.id
                )
                .catch((err) => {
                  toastr.error(err.message);
                })
            }
            onPlayNext={() =>
              currSession
                ?.playNext(
                  track.albumID === "youtube" ? `youtube:${track.id}` : track.id
                )
                .catch((err) => {
                  toastr.error(err.message);
                })
            }
            onAddToQueue={() =>
              currSession
                ?.addToQueue(
                  track.albumID === "youtube" ? `youtube:${track.id}` : track.id
                )
                .catch((err) => {
                  toastr.error(err.message);
                })
            }
            albumName={track.album || ""}
            albumId={track.albumID || ""}
            artistName={track.artist || ""}
            artistId={track.artistID || ""}
            thumbnail={track.thumbnail || ""}
          />
        ))
    );
  }, [currSession]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        paddingLeft: "40px",
        width: "100%",
        marginBottom: "64px",
      }}
    >
      <Box sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "20px",
        width: "100%",
        pr: "10px",
      }}>
        <Typography
          variant="h4"
          sx={{ color: "#FFFFFF", fontWeight: "bold" }}
        >
          Favourites
        </Typography>

        <Typography
          variant="subtitle1"
          sx={{ color: "#FFFFFF", fontWeight: "bold" }}
        >
          {favManager.getAll(false).length} / {currSession?.users.find(u => u.id === localStorage.getItem("id"))?.premium ? 500 : 50}
        </Typography>
      </Box>
      <Tabs
        value={tab}
        onChange={(_, v) => setTab(v)}
        sx={{ marginBottom: "20px", width: "100%" }}
        variant="fullWidth"
      >
        <Tab label="Tracks" />
        <Tab label="Albums" />
        <Tab label="Artists" />
      </Tabs>

      {tab === 0 && (
        <Box
          sx={{
            display: tracks && tracks.length === 0 ? "none" : "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            width: "95%",
            marginBottom: "64px",
          }}
        >
          <Table
            sx={{
              width: "100%",
              size: "small",
              border: "none",
              marginTop: "10px",
              borderTop: "1px solid gray",
              borderBottom: "1px solid gray",
              "& .MuiTableCell-root": {
                border: "none",
                fontSize: "16px",
                padding: "5px",
              },
            }}
          >
            <TableBody>{tracks ?? <CenteredLoader />}</TableBody>
          </Table>
        </Box>
      )}

      {tab === 1 && (
        <Box
          style={{
            width: "100%",
            display: albums && albums.length === 0 ? "none" : "block",
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: "#FFFFFF", fontWeight: "bold", marginTop: "20px" }}
          >
            Albums
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <Grid
              container
              spacing={2}
              style={{
                paddingTop: "1rem",
                width: "100%",
                marginBottom: "64px",
              }}
              xl={12}
            >
              {albums ?? <CenteredLoader />}
            </Grid>
          </Box>
        </Box>
      )}

      {tab === 2 && (
        <Box
          style={{
            width: "100%",
            display: artists && artists.length === 0 ? "none" : "block",
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: "#FFFFFF", fontWeight: "bold", marginTop: "20px" }}
          >
            Artists
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <Grid
              container
              spacing={2}
              style={{
                paddingTop: "1rem",
                width: "100%",
                marginBottom: "64px",
              }}
              xl={12}
            >
              {artists ?? <CenteredLoader />}
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Favourites;

function CenteredLoader(): JSX.Element {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <CircularProgress />
    </Box>
  );
}
