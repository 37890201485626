import {
  AppBar,
  Avatar,
  Box,
  Chip,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  DoNotDisturbAlt,
  DoNotDisturbRounded,
  DoNotStep,
  DoNotStepRounded,
  MusicNote,
  MusicNoteRounded,
  ShareRounded,
  StarRounded,
} from "@mui/icons-material";

// components
import AccountMenu from "./AccountMenu";

// states
import useUI from "../states/UIStates";
import { useEffect, useState } from "react";
import SessionUser from "./SessionUser";
import SharePopup from "./SharePopup";
import SessionManager from "../sessionManager";
import { Types } from "../types";
import SessionSettingsPopup from "./SessionSettingsPopup";

function Navbar({ session }: { session: SessionManager | undefined }) {
  const { windowFullScreen } = useUI();
  const [shareOpen, setShareOpen] = useState<boolean>(false);
  const [settingsOpen, setSettingsOpen] = useState<boolean>(false);

  const [users, setUsers] = useState<Types.Member[]>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorTargetUser, setAnchorTargetUser] = useState<null | Types.Member>(
    null
  );
  const anchorOpen = Boolean(anchorEl);

  useEffect(() => {
    if (!session?.SessionID) return;

    session.on("playback_start", () => {
      console.log(session.users);
      setUsers([...session.users]);
    });
    session.on("session_resync_users", () => {
      console.log("Resyncing users");
      console.log(session.users);
      setUsers([...session.users]);
    });
  }, [session]);

  useEffect(() => {
    if (anchorEl === null) setAnchorTargetUser(null);
  }, [anchorEl]);

  return (
    <AppBar
      sx={{
        width: "100%",
        height: "64px",
        zIndex: 1500,
        marginTop: windowFullScreen ? "-64px" : "0px",
        transition: "all 1s ease-in-out",
        backgroundColor: "#000000",
        backgroundImage: "none",
        boxShadow: "none",

        "& .MuiAppBar-root": {
          boxShadow: "none",
          backgroundColor: "#000000",
          backgroundImage: "none",
        },
      }}
    >
      <SharePopup session={session} openHook={[shareOpen, setShareOpen]} />
      <SessionSettingsPopup
        session={session}
        openHook={[settingsOpen, setSettingsOpen]}
      />
      <Menu
        anchorEl={anchorEl}
        open={anchorOpen}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          zIndex: 2000,
          padding: "none",

          "& .MuiMenu-paper": {
            backgroundColor: "#121212",
            color: "#fff",
            padding: "none",
            paddingTop: "none",
          },
        }}
      >
        <Box
          sx={{
            width: "250px",
          }}
        >
          <Box
            sx={{
              backgroundImage: anchorTargetUser?.bannerURL
                ? `linear-gradient(0deg, #0005, #0005), url(${(
                    (anchorTargetUser?.bannerURL || "") as string
                  ).replace(".png", ".gif")}?size=480), url(${
                    anchorTargetUser?.bannerURL
                  }?size=480)`
                : `linear-gradient(0deg, #0005, #0005) ${anchorTargetUser?.bannerColor}`,
              backgroundSize: "cover",
              height: "95px",
              width: "100%",

              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-end",
              padding: "5px",
              gap: "5px",
            }}
          >
            {anchorTargetUser?.premium && (
              <Tooltip title="Premium" placement="top" arrow>
                <StarRounded
                  sx={{
                    color: "#ffd700",
                    filter: "drop-shadow(0px 0px 5px #ffd700)",
                  }}
                  fontSize="large"
                />
              </Tooltip>
            )}
            {anchorTargetUser?.id === session?.users[0].id && (
              <Tooltip title="Host" placement="top" arrow>
                <MusicNoteRounded color="primary" fontSize="large" />
              </Tooltip>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              marginTop: "-60px",
              padding: "0 5px",
            }}
          >
            <Avatar
              src={anchorTargetUser?.avatarURL}
              sx={{
                width: "60px",
                height: "60px",
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                marginLeft: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                }}
              >
                {anchorTargetUser?.username}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display:
                localStorage.getItem("id") === session?.users[0].id
                  ? "flex"
                  : "none",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Tooltip title="Make Host" placement="bottom" arrow>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  width: "calc(100% / 3)",
                  height: "50px",
                  borderRadius: "0px",

                  cursor: "pointer",

                  "&:hover": {
                    backgroundColor: "#ffffff11",
                  },
                }}
                onClick={() => {
                  if (!session) return;
                  session.socket.emit(
                    "session_setHost",
                    anchorTargetUser?.socket
                  );
                  setAnchorEl(null);
                }}
              >
                <MusicNoteRounded
                  sx={{ color: (theme) => theme.palette.primary.main }}
                />
              </Box>
            </Tooltip>
            <Tooltip title="Kick" placement="bottom" arrow>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  width: "calc(100% / 3)",
                  height: "50px",
                  borderRadius: "0px",

                  cursor: "pointer",

                  "&:hover": {
                    backgroundColor: "#ffffff11",
                  },
                }}
                onClick={() => {
                  if (!session) return;
                  session.socket.emit(
                    "session_kickUser",
                    anchorTargetUser?.socket
                  );
                  setAnchorEl(null);
                }}
              >
                <DoNotStepRounded color="error" />
              </Box>
            </Tooltip>
            <Tooltip title="Ban" placement="bottom" arrow>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  width: "calc(100% / 3)",
                  height: "50px",
                  borderRadius: "0px",

                  cursor: "pointer",

                  "&:hover": {
                    backgroundColor: "#ffffff11",
                  },
                }}
                onClick={() => {
                  if (!session) return;
                  session.socket.emit(
                    "session_banUser",
                    anchorTargetUser?.socket
                  );
                  setAnchorEl(null);
                }}
              >
                <DoNotDisturbRounded color="error" />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Menu>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 20px",
          paddingLeft: "30px",
          height: "100%",
        }}
      >
        <Stack
          direction="row"
          sx={{
            gap: "10px",
            letterSpacing: "0.05em",
          }}
        >
          <h1
            style={{
              margin: 0,
              fontSize: "1.5rem",
            }}
          >
            <strong
              style={{
                color: "#54AAF0",
              }}
            >
              Sync
            </strong>
            Sound
          </h1>
        </Stack>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "15px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
              alignItems: "center",
              justifyContent: "flex-end",
              backgroundColor: "#242429",
              gap: "10px",

              borderRadius: "50px",
            }}
          >
            <Box
              sx={{
                width: "40px",
                height: "40px",

                borderRadius: "50%",

                display: "flex",
                alignItems: "center",
                justifyContent: "center",

                cursor: "pointer",

                "&:hover": {
                  backgroundColor: "#ffffff11",
                },
              }}
              onClick={() => setShareOpen(true)}
            >
              <ShareRounded
                sx={{ color: (theme) => theme.palette.primary.main }}
              />
            </Box>
            {users
              .filter((user) => user.id !== localStorage.getItem("id"))
              .map((user, index) => (
                <SessionUser
                  key={user.socket}
                  user={user}
                  isHost={index === 0}
                  canModerate={users[0].socket === session?.socket.id}
                  onUserProfile={(event, user) => {
                    setAnchorTargetUser(user);
                    setAnchorEl(event.currentTarget);
                  }}
                />
              ))}
          </Box>
          <AccountMenu />
        </Box>
      </Box>
    </AppBar>
  );
}

export default Navbar;
