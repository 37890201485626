import React from "react";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function SearchBar({
  value,
  style,
}: {
  value?: string;
  style?: React.CSSProperties;
}) {
  const navigate = useNavigate();
  const [search, setSearch] = React.useState("");

  const Search = () => {
    if (!search || search === value) return;

    if (search.startsWith("pid:"))
      return navigate("/playlist/" + search.replace("pid:", "").trim());
    if (search.startsWith("aid:"))
      return navigate("/album/" + search.replace("aid:", "").trim());
    if (search.startsWith("arid:"))
      return navigate("/artist/" + search.replace("arid:", "").trim());

    if (search.includes("youtube.com")) {
      let url = new URL(search);
      let videoId = url.searchParams.get("v");
      if (videoId) {
        return navigate("/youtube/" + videoId);
      }
    }
    if (search.includes("youtu.be")) {
      let url = new URL(search);
      let videoId = url.pathname.replace("/", "");
      if (videoId) {
        return navigate("/youtube/" + videoId);
      }
    }

    // Filter all non url friendly characters using regex
    let cleanSearch = encodeURIComponent(search);
    localStorage.setItem("lastQuery", cleanSearch);
    navigate("/search/" + cleanSearch);
  };

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...style,
        gap: "1rem",
      }}
    >
      <TextField
        defaultValue={value ? value : search}
        fullWidth
        sx={{
          width: "100%",
          backgroundColor: "#242429",
          borderRadius: "15px",
          color: "white",

          // apply the borderRadius to the TextField root
          "& .MuiOutlinedInput-root": {
            borderRadius: "15px",
          },
        }}
        variant="outlined"
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            Search();
          }
        }}
        onChange={(event) => {
          setSearch(event.target.value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon
                sx={{
                  color: "white",
                }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}

export default SearchBar;
