import { useState } from "react";
import SessionManager from "../sessionManager";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Collapse,
  Divider,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { socket } from "../App";
import { Types } from "../types";

const dividerBoxStyle = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  width: "200px",
  textAlign: "center",
  height: "200px",
} as React.CSSProperties;

function SessionPopup({
  currSessionProp,
}: {
  currSessionProp: [
    SessionManager | undefined,
    (session: SessionManager | undefined) => void
  ];
}) {
  const [currSession, setCurrSession] = currSessionProp;
  const [open, setOpen] = useState<boolean>(
    !currSession?.SessionID &&
      !window.location.pathname.includes("join") &&
      !window.location.pathname.includes("login")
  );
  const [step, setStep] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);

  const [joinID, setJoinID] = useState<string>("");
  const [sessionSettings, setSessionSettings] = useState<{
    hostOnly: boolean;
    allowSkip: boolean;
  }>({
    hostOnly: false,
    allowSkip: true,
  });

  if (
    !window.location.href.includes("login") &&
    (!localStorage.getItem("id") ||
      !localStorage.getItem("token") ||
      !localStorage.getItem("username"))
  ) {
    window.location.href = "/login";
  }

  if (step === 0)
    return (
      <Backdrop open={open} style={{ zIndex: 5000 }}>
        <Box
          style={{
            backgroundColor: "#202020",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
          }}
        >
          <Typography
            style={{
              color: "white",
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            Hey there 👋!
          </Typography>
          <Typography
            style={{
              color: "white",
              fontSize: "1rem",
              fontStyle: "italic",
            }}
          >
            Join a session or create one to get started!
          </Typography>
          <Collapse in={error !== null}>
            {" "}
            <Alert severity="error">{error}</Alert>{" "}
          </Collapse>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box style={dividerBoxStyle}>
              <Button
                style={{
                  fontWeight: "bold",
                  fontSize: "1.25rem",
                }}
                onClick={() => {
                  setStep(2);
                }}
              >
                CREATE
              </Button>
            </Box>
            <Divider
              orientation="vertical"
              style={{
                height: "150px",
              }}
            >
              <Typography
                style={{
                  color: "white",
                  fontSize: "0.75rem",
                  fontStyle: "italic",
                }}
              >
                OR
              </Typography>
            </Divider>
            <Box style={dividerBoxStyle}>
              <Button
                style={{
                  fontWeight: "bold",
                  fontSize: "1.25rem",
                }}
                onClick={() => {
                  setError(null);
                  setStep(1);
                }}
              >
                JOIN
              </Button>
            </Box>
          </Box>
        </Box>
      </Backdrop>
    );
  else if (step === 1)
    return (
      <Backdrop open={open} style={{ zIndex: 5000 }}>
        <Box
          style={{
            backgroundColor: "#202020",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
          }}
        >
          <Typography
            style={{
              color: "white",
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            Alright, almost there! 🚀
          </Typography>
          <Typography
            style={{
              color: "white",
              fontSize: "1rem",
              fontStyle: "italic",
            }}
          >
            Enter the session ID or link to join a session.
          </Typography>

          <Collapse in={error !== null}>
            {" "}
            <Alert severity="error">{error}</Alert>{" "}
          </Collapse>

          <TextField
            style={{
              width: "100%",
              margin: "30px 30px 20px 30px",
            }}
            label="Session ID"
            variant="outlined"
            onChange={(e) => {
              setJoinID(e.target.value);
            }}
          />

          <Button
            variant="contained"
            disabled={joinID.length === 0}
            onClick={() => {
              let sessionID = `${joinID}`;

              const isUrl = sessionID.includes("http");

              if (isUrl) {
                const url = new URL(sessionID);
                sessionID = url.pathname.split("/").pop() || "";
              }

              socket.emit(
                "joinSession",
                sessionID.trim().toLowerCase(),
                (res: Types.SessionJoinResponse | Types.SocketError) => {
                  if (res.error) {
                    setError(res.message);
                    return console.error(res.error);
                  }

                  console.log(res);
                  setCurrSession(
                    new SessionManager({
                      sessionID: res.sessionID,
                      socket: socket,
                      settings: res.settings,
                      playback: res.playback,
                      queue: res.queue,
                      users: res.users,
                    })
                  );
                  setOpen(false);
                }
              );
            }}
          >
            JOIN
          </Button>
        </Box>
      </Backdrop>
    );
  else if (step === 2)
    return (
      <Backdrop open={open} style={{ zIndex: 5000 }}>
        <Box
          style={{
            backgroundColor: "#202020",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
          }}
        >
          <Typography
            style={{
              color: "white",
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            Lets customize your session! 🚀
          </Typography>

          <Collapse in={error !== null}>
            <Alert severity="error">{error}</Alert>{" "}
          </Collapse>

          <Box
            sx={{
              mt: "20px",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "15px",
            }}
          >
            <Switch
              inputProps={{ "aria-label": "controlled" }}
              checked={sessionSettings.hostOnly}
              onChange={(e) =>
                setSessionSettings({
                  ...sessionSettings,
                  hostOnly: e.target.checked,
                })
              }
            />
            <Box>
              <Typography
                sx={{
                  color: "white",
                  fontSize: "1rem",
                }}
              >
                Host only
              </Typography>
              <Typography
                sx={{
                  color: "white",
                  fontSize: "0.75rem",
                }}
                variant="caption"
              >
                Only the host can control the session
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              mt: "10px",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "15px",
            }}
          >
            <Switch
              inputProps={{ "aria-label": "controlled" }}
              checked={sessionSettings.allowSkip}
              disabled={sessionSettings.hostOnly}
              onChange={(e) =>
                setSessionSettings({
                  ...sessionSettings,
                  allowSkip: e.target.checked,
                })
              }
            />
            <Box>
              <Typography
                sx={{
                  color: "white",
                  fontSize: "1rem",
                }}
              >
                Allow Skipping
              </Typography>
              <Typography
                sx={{
                  color: "white",
                  fontSize: "0.75rem",
                }}
                variant="caption"
              >
                Allow users to skip tracks and use play now
              </Typography>
            </Box>
          </Box>

          <Button
            sx={{
              mt: "20px",
            }}
            variant="contained"
            onClick={() => {
              socket.emit(
                "createSession",
                sessionSettings,
                (res: Types.SessionJoinResponse | Types.SocketError) => {
                  if (res.error) {
                    setError(res.message);
                    return console.error(res.error);
                  }

                  console.log(res);

                  setCurrSession(
                    new SessionManager({
                      sessionID: res.sessionID,
                      socket: socket,
                      settings: res.settings,
                      playback: res.playback,
                      queue: res.queue,
                      users: res.users,
                    })
                  );
                  setOpen(false);
                }
              );
            }}
          >
            CREATE
          </Button>
        </Box>
      </Backdrop>
    );
  else
    return (
      <Backdrop open={open} style={{ zIndex: 5000 }}>
        <Box
          style={{
            backgroundColor: "#202020",
            padding: "30px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
          }}
        >
          <Typography
            style={{
              color: "white",
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            Something went wrong!
          </Typography>
        </Box>
      </Backdrop>
    );
}

export default SessionPopup;
