import {
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SessionManager from "../sessionManager";
import {
  PlayArrow,
  Favorite,
  FavoriteBorder,
  PlaylistAdd,
  MoreHoriz,
  YouTube as YoutubeIcon,
} from "@mui/icons-material";
import { favManager } from "../App";
import { Types } from "../types";
import { YouTube } from "../youtube";
import SearchBar from "../components/Searchbar";

function Youtube({ currSession }: { currSession: SessionManager | undefined }) {
  const id = useParams<{ id: string }>().id;
  const [videoData, setVideoData] = useState<Types.YoutubeInfo | null>();

  const [favourite, setFavourite] = useState<boolean>(favManager.has(id || ""));

  const [videoOptionsMenuAnchor, setVideoOptionsMenuAnchor] =
    useState<null | HTMLElement>(null);
  const videoOptionsMenuOpen = Boolean(videoOptionsMenuAnchor);

  useEffect(() => {
    if (!id) return;
    setVideoData(null);

    YouTube.getVideoById(id).then((data) => {
      setVideoData(data);
    });
  }, [id]);

  if (videoData === null)
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );

  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        marginTop: "40px",
        marginBottom: "60px",
      }}
    >
      <Box sx={{
        width: "95%",
        marginLeft: "40px",
        marginBottom: "40px",
      }}>
        <SearchBar value={`https://www.youtube.com/watch?v=${id}`} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          marginLeft: "40px",
        }}
      >
        <Avatar
          src={videoData?.thumbnail}
          variant="square"
          sx={{ height: "200px", width: "auto" }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginLeft: "40px",
          }}
        >
          <Typography
            sx={{
              fontSize: "40px",
              fontWeight: "bold",
            }}
          >
            {videoData?.title}
          </Typography>
          <Typography
            sx={{
              fontSize: "20px",
              color: "gray",
            }}
          >
            by {videoData?.author}
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{
                fontSize: "15px",
                fontWeight: "bold",
              }}
              onClick={() => {
                if (!currSession) return;
                currSession.playNow(`youtube:${id}`);
              }}
            >
              <PlayArrow />
              Play
            </Button>
            <Tooltip title="Open on Youtube">
              <Button
                variant="outlined"
                size="small"
                sx={{
                  border: "none",
                }}
                onClick={() =>
                  window.open(`https://www.youtube.com/watch?v=${id}`, "_blank")
                }
              >
                <YoutubeIcon />
              </Button>
            </Tooltip>
            <Tooltip
              title={`${favourite ? "Remove from" : "Add to"} favourites`}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{
                  border: "none",
                }}
                onClick={() => {
                  if (!videoData) return;
                  if (favourite) {
                    favManager.remove(videoData.id);
                    setFavourite(false);
                  } else {
                    favManager.add(videoData.id, {
                      id: videoData.id,
                      name: videoData.title,
                      type: "youtube",
                      artist: videoData.author,
                      album: "youtube",
                      albumID: "youtube",
                      artistID: "youtube",
                      thumbnail: videoData.thumbnail,
                    });
                    setFavourite(true);
                  }
                }}
              >
                {favourite ? <Favorite /> : <FavoriteBorder />}
              </Button>
            </Tooltip>
            <Tooltip title="Add to Queue">
              <Button
                variant="outlined"
                size="small"
                sx={{
                  border: "none",
                }}
                onClick={() => {
                  if (!currSession || !videoData) return;
                  currSession.addToQueue(`youtube:${id}`);
                }}
              >
                <PlaylistAdd />
              </Button>
            </Tooltip>
            <Tooltip title="More">
              <Button
                variant="outlined"
                size="small"
                sx={{
                  border: "none",
                }}
                onClick={(e) => setVideoOptionsMenuAnchor(e.currentTarget)}
              >
                <MoreHoriz />
              </Button>
            </Tooltip>
            <Menu
              anchorEl={videoOptionsMenuAnchor}
              open={videoOptionsMenuOpen}
              onClose={() => setVideoOptionsMenuAnchor(null)}
              onClick={() => setVideoOptionsMenuAnchor(null)}
              sx={{
                "& .MuiMenuItem-root": {
                  fontSize: "16px",
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  if (!currSession || !videoData) return;
                  currSession.playNext(`youtube:${id}`);
                }}
              >
                Play Next
              </MenuItem>
              <MenuItem
                onClick={() => {
                  if (!currSession || !videoData) return;
                  currSession.addToQueue(`youtube:${id}`);
                }}
              >
                Add to Queue
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() =>
                  window.open(`https://www.youtube.com/watch?v=${id}`, "_blank")
                }
              >
                Open on Youtube
              </MenuItem>
            </Menu>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export default Youtube;
